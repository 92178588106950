import { MeetingStatus } from '../../../../../types/meetingDetails.types';

export const getOpenButtonText = (
  meetingStatus?: MeetingStatus,
  isAnalysisDone?: boolean
): string => {
  switch (meetingStatus) {
    case MeetingStatus.IN_PROGRESS: {
      return 'In Progress';
    }
    case MeetingStatus.CALL_POST_PROCESSING_FAILED:
    case MeetingStatus.BOT_FAILED_TO_JOIN: {
      return 'N/A';
    }
    default: {
      return isAnalysisDone ? 'Open' : 'Processing';
    }
  }
};
