import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';
import { Account } from '../../types/account.types';
import { ApiResponseData } from '../../types/apiResponse.types';
import { Contact } from '../../types/contact.types';
import { EmailAnalysis } from '../../types/emailAnalysis.types';
import { MeetingAnalysis } from '../../types/meetingDetails.types';
import {
  RelationshipEvent,
  RelationshipEventNavigation,
} from '../../types/relationshipEvent.types';
import { RootState } from '../store';

export const getThreadDetails = createAsyncThunk<
  {
    account: Account | null;
    touchPoint: RelationshipEvent | null;
    analysis: EmailAnalysis | null;
    contacts: Contact[] | null;
    prevTouchPoint: RelationshipEventNavigation | null;
    nextTouchPoint: RelationshipEventNavigation | null;
  },
  string
>(
  'threadDetails/getThreadDetails',
  async (
    threadId: string,
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.get<
        ApiResponseData<{
          account?: Account | null;
          touchPoint?: RelationshipEvent | null;
          analysis?: EmailAnalysis | MeetingAnalysis | null;
          contacts?: Contact[] | null;
          prevTouchPoint?: RelationshipEventNavigation | null;
          nextTouchPoint?: RelationshipEventNavigation | null;
        }>
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/touch-points/${threadId}`,
        { signal }
      );

      const {
        account,
        touchPoint,
        analysis,
        contacts,
        prevTouchPoint,
        nextTouchPoint,
      } = response.data.payload;

      return fulfillWithValue({
        account: account || null,
        touchPoint: touchPoint || null,
        contacts: contacts || null,
        analysis: analysis && 'branch' in analysis ? analysis : null,
        prevTouchPoint: prevTouchPoint || null,
        nextTouchPoint: nextTouchPoint || null,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);
