import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from '../../store/store';
import { subjectIcon } from './assets';
import { Options } from '../Users/components/Table/components/Options';
import { PopupOption } from '../../components/Popup/PopupOption';
import { RenameAccIcon } from '../MyDeals/DealsTable/assets/RenameAccIcon';
import { getMeetings } from '../../store/myMeetings/myMeetings.actions';
import { RemoveEngIcon } from '../MyDeals/DealsTable/assets/RemoveEngIcon';
import { Avatar } from '../../components';
import {
  MeetingInfo,
  MeetingParticipant,
} from '../../store/myMeetings/myMeetings.types';

interface TableRowProps {
  subject: string;
  participants: MeetingParticipant[];
  date: string;
  link: string;
}

export const MyMeetings: React.FC = (): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { futureMeetings, pastMeetings } = useSelector(
    (state: RootState) => state.myMeetings
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      const { userId, orgId } = user;
      dispatch(getMeetings(orgId, userId));
    }
  }, [dispatch, user]);

  const attrs = {
    wrapper: {
      className: 'my-meetings',
    },

    tableTitle: {
      className: 'my-meetings-table-title',
    },

    tableTitleRow: {
      wrapper: {
        className: 'my-meetings__title-row',
      },

      label: {
        className: 'my-meetings__title-row-label',
      },
    },

    table: {
      wrapper: {
        className: 'my-meetings__table',
      },

      row: {
        wrapper: {
          className: 'my-meetings__table__row',
        },

        subject: {
          wrapper: {
            className: 'my-meetings__table__row__subject',
          },

          img: {
            className: 'my-meetings__table__row__subject-img',
            src: subjectIcon,
          },

          text: {
            className: 'my-meetings__table__row__subject-text',
          },
        },

        participants: {
          wrapper: {
            className: 'my-meetings__table__row__participants',
          },

          avatar: {
            className: 'my-meetings__table__row__participants-avatar',
            inMeetingsRow: true,
          },
        },

        time: {
          wrapper: {
            className: 'my-meetings__table__row__time',
          },

          value: {
            className: 'my-meetings__table__row__time-value',
          },
        },

        date: {
          wrapper: {
            className: 'my-meetings__table__row__date',
          },

          value: {
            className: 'my-meetings__table__row__date-value',
          },
        },

        link: {
          wrapper: {
            className: 'my-meetings__table__row__link',
          },

          value: {
            className: 'my-meetings__table__row__link-value',
          },
        },

        buttons: {
          wrapper: {
            className: 'my-meetings__table__row__buttons',
          },

          addNotetaker: {
            className: 'my-meetings__table__row__buttons-add-notetaker',
          },

          join: {
            className: 'my-meetings__table__row__buttons-join',
          },

          dotMenu: {
            wrapper: {
              className: 'my-meetings__table__row__buttons__dot-menu',
            },
          },
        },
      },
    },

    noDataMessage: {
      className: 'my-meetings__table-no-data-message',
    },
  };

  const options = [
    <PopupOption
      key="Reschedule"
      className="options__option"
      text="Reschedule"
      onChoose={() => {
        console.log('Reschedule clicked');
      }}
      Icon={RenameAccIcon}
      disabled
    />,
    <PopupOption
      key="Cancel"
      className="options__option"
      text="Cancel"
      onChoose={() => {
        console.log('Cancel clicked');
      }}
      Icon={RemoveEngIcon}
      disabled
    />,
  ];

  const createTableRow = (props: TableRowProps) => {
    const { subject, participants, date, link } = props;

    const timeToShow = moment(date).format('dddd, h:mm a');

    const dateToShow = moment(date).format('LL');

    const openMeeting = () => {
      if (link?.length && link.includes('meet.google.com')) {
        window.open(link, '_blank');
      }
    };

    return (
      <div {...attrs.table.row.wrapper}>
        <div {...attrs.table.row.subject.wrapper}>
          <img {...attrs.table.row.subject.img} alt="" />
          <div {...attrs.table.row.subject.text}>{subject}</div>
        </div>
        <div {...attrs.table.row.participants.wrapper}>
          {participants.map((el, i) => (
            <Avatar
              {...attrs.table.row.participants.avatar}
              key={`${el.email}|${i}`}
              image={el.metadata?.avatarSrc || ''}
              userName={el.metadata.name || ''}
            />
          ))}
        </div>
        <div {...attrs.table.row.time.wrapper}>
          <div {...attrs.table.row.time.value}>{timeToShow}</div>
        </div>
        <div {...attrs.table.row.date.wrapper}>
          <div {...attrs.table.row.date.value}>{dateToShow}</div>
        </div>
        <div {...attrs.table.row.link.wrapper}>
          <div {...attrs.table.row.link.value}>{link}</div>
        </div>
        <div {...attrs.table.row.buttons.wrapper}>
          <div {...attrs.table.row.buttons.addNotetaker}>Add Notetaker</div>
          <div {...attrs.table.row.buttons.join} onClick={openMeeting}>
            Join
          </div>
          <div {...attrs.table.row.buttons.dotMenu.wrapper}>
            <Options options={options} />
          </div>
        </div>
      </div>
    );
  };

  const mapTableData = () => {
    let mappedUpcomingMeetsRows: JSX.Element[] | JSX.Element = (
      <div {...attrs.noDataMessage}>No upcoming meetings</div>
    );

    let mappedPastMeetsRows: JSX.Element[] | JSX.Element = (
      <div {...attrs.noDataMessage}>No past meetings</div>
    );

    const mapRows = (meets: MeetingInfo[]) => {
      return meets.map((el, i) => (
        <React.Fragment key={i}>
          {createTableRow({
            subject: el.summary,
            participants: el.participants?.map((participant) => participant),
            date: el.start,
            link: el.joinUrl,
          })}
        </React.Fragment>
      ));
    };

    if (futureMeetings?.length) {
      mappedUpcomingMeetsRows = mapRows(futureMeetings);
    }

    if (pastMeetings?.length) {
      mappedPastMeetsRows = mapRows(pastMeetings);
    }

    return { mappedUpcomingMeetsRows, mappedPastMeetsRows };
  };

  const { mappedUpcomingMeetsRows, mappedPastMeetsRows } = mapTableData();

  const tableTitleRow = (
    <div {...attrs.tableTitleRow.wrapper}>
      <div {...attrs.tableTitleRow.label}>Meeting Subject</div>
      <div {...attrs.tableTitleRow.label}>Participants</div>
      <div {...attrs.tableTitleRow.label}>Time</div>
      <div {...attrs.tableTitleRow.label}>Date</div>
      <div {...attrs.tableTitleRow.label}>Link</div>
      <div {...attrs.tableTitleRow.label} />
      <div {...attrs.tableTitleRow.label} />
    </div>
  );

  const upcomingTableTitleRow = futureMeetings?.length ? tableTitleRow : null;

  const pastTableTitleRow =
    !futureMeetings?.length && pastMeetings?.length ? tableTitleRow : null;

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.tableTitle}>Upcoming meetings</div>
      {upcomingTableTitleRow}
      <div {...attrs.table.wrapper}>{mappedUpcomingMeetsRows}</div>
      <div {...attrs.tableTitle}>Past meetings</div>
      {pastTableTitleRow}
      <div {...attrs.table.wrapper}>{mappedPastMeetsRows}</div>
    </div>
  );
};
