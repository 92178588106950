import React from 'react';

export const RenameAccIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.968 2.03223C13.0363 1.10055 11.5257 1.10059 10.5941 2.03233L2.62739 9.99996C2.35651 10.2709 2.16611 10.6116 2.07737 10.9843L1.34698 14.052C1.30676 14.2209 1.35706 14.3985 1.47983 14.5213C1.60261 14.6441 1.78029 14.6944 1.94919 14.6542L5.01669 13.9238C5.3895 13.8351 5.73035 13.6446 6.00131 13.3736L13.9681 5.4059C14.8996 4.47425 14.8996 2.96383 13.968 2.03223ZM11.3012 2.73939C11.8423 2.19822 12.7197 2.19819 13.2608 2.73933C13.8019 3.28043 13.802 4.15771 13.2609 4.69884L12.6667 5.29313L10.7071 3.33358L11.3012 2.73939ZM10 4.04072L11.9596 6.00028L5.29416 12.6665C5.15406 12.8066 4.97783 12.9051 4.78507 12.951L2.50798 13.4932L3.05018 11.2159C3.09606 11.0233 3.19449 10.8471 3.33454 10.707L10 4.04072Z"
        fill="white"
      />
    </svg>
  );
};
