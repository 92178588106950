import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Avatar } from '../../../../components';
import { RootState } from '../../../../store/store';
import LabelValueRow from '../../../../components/LabelValueRow/LabelValueRow';
import { noDataTeams } from './assets';
import { TeamProfile } from './components/teamProfile/TeamProfile';
import { setAccountTeamsSectionActive } from '../../../../store/auth/auth.actions';

type ActiveTab = 'myOrg' | 'myTeams';

export const MyOrgAndTeams: React.FC = (): JSX.Element => {
  const { org, user, isAccountTeamTabActive } = useSelector(
    (state: RootState) => state.auth
  );

  const { teams } = useSelector((state: RootState) => state.teams);

  const history = useHistory();

  const [activeTab, setActiveTab] = useState<ActiveTab>('myOrg');

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAccountTeamTabActive) {
      setActiveTab('myTeams');
      dispatch(setAccountTeamsSectionActive(false));
    }
  }, [isAccountTeamTabActive, dispatch]);

  const changeTab = (tab: ActiveTab) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  };

  const goToTeams = () => {
    if (user) {
      history.push(`/orgs/${user.orgId}/admin-settings/teams`);
    }
  };

  const attrs = {
    wrapper: {
      className: 'org-team',
    },

    navigation: {
      wrapper: {
        className: 'org-team__navigation-section',
      },

      myOrgCell: {
        className: `${
          activeTab === 'myOrg' ? 'active' : ''
        } org-team__navigation-section-cell`,
        onClick: () => changeTab('myOrg'),
      },

      myTeamsCell: {
        className: `${
          activeTab === 'myTeams' ? 'active' : ''
        } org-team__navigation-section-cell`,
        onClick: () => changeTab('myTeams'),
      },
    },

    body: {
      wrapper: {
        className: 'org-team__body',
      },

      myOrg: {
        wrapper: {
          className: 'org-team__body__orgs',
        },

        avatarSection: {
          wrapper: {
            className: 'org-team__body__orgs__avatar',
          },

          avatar: {
            image: org?.avatar,
            className: 'org-team__body__orgs__avatar-image',
          },
        },

        rows: {
          OrgName: {
            label: 'Organization name',
            value: org?.name,
          },

          Url: {
            label: 'URL',
            value: org?.url,
          },

          Email: {
            label: 'Primary Email',
            value: org?.primaryEmail,
          },

          Location: {
            label: 'Location',
            value: org?.location || '',
          },

          Owner: {
            label: 'Owner',
            value: org?.ownerName,
          },

          Admins: {
            label: 'Admins',
            value:
              org?.adminNames && org?.adminNames.length
                ? org.adminNames.map((el) => el).join(', ')
                : '',
          },

          Members: {
            label: 'Members',
            value: org?.usersCount.toString() || '0',
          },

          CreatedOn: {
            label: 'Created On',
            value: moment(org?.createdAt).format('LL') || '',
          },
        },
      },

      teams: {
        wrapper: {
          className: 'no-data org-team__body__teams',
        },

        empty: {
          wrapper: {
            className: 'org-team__body__teams__empty',
          },

          img: {
            className: 'org-team__body__teams__empty-img',
            src: noDataTeams,
          },

          boldMessage: {
            className: 'org-team__body__teams__empty-bold-message',
          },

          bottomMessage: {
            wrapper: {
              className: 'org-team__body__teams__empty__bottom-message',
            },

            text: {
              className: 'org-team__body__teams__empty__bottom-message-text',
            },

            link: {
              className: 'org-team__body__teams__empty__bottom-message-link',
              onClick: goToTeams,
            },
          },
        },
      },
    },
  };

  const orgRows = (
    <>
      <LabelValueRow {...attrs.body.myOrg.rows.OrgName} />
      <LabelValueRow {...attrs.body.myOrg.rows.Url} />
      <LabelValueRow {...attrs.body.myOrg.rows.Email} />
      <LabelValueRow {...attrs.body.myOrg.rows.Location} />
      <LabelValueRow {...attrs.body.myOrg.rows.Owner} />
      <LabelValueRow {...attrs.body.myOrg.rows.Admins} />
      <LabelValueRow {...attrs.body.myOrg.rows.Members} />
      <LabelValueRow {...attrs.body.myOrg.rows.CreatedOn} />
    </>
  );

  const userIsDealmakerCheck = user?.role === 'dealmaker';

  const noDataTeamsSection = (
    <div {...attrs.body.teams.wrapper}>
      <div {...attrs.body.teams.empty.wrapper}>
        <img {...attrs.body.teams.empty.img} alt="no teams" />
        <span {...attrs.body.teams.empty.boldMessage}>
          {userIsDealmakerCheck
            ? 'You are not a member of any \n team at the moment'
            : 'There are no existing teams'}
        </span>
        <div {...attrs.body.teams.empty.bottomMessage.wrapper}>
          <span {...attrs.body.teams.empty.bottomMessage.text}>
            {userIsDealmakerCheck
              ? 'Please contact your team leader to be \n added to one'
              : 'Create your first team '}
          </span>
          {userIsDealmakerCheck ? null : (
            <span {...attrs.body.teams.empty.bottomMessage.link}>here</span>
          )}
        </div>
      </div>
    </div>
  );

  const myTeams = teams.length
    ? teams.map((el) => (
        <React.Fragment key={el.teamId}>
          <TeamProfile team={el} />
        </React.Fragment>
      ))
    : noDataTeamsSection;

  const teamsTab = activeTab === 'myTeams' ? myTeams : null;

  const myOrganization =
    activeTab === 'myOrg' ? (
      <div {...attrs.body.myOrg.wrapper}>
        <div {...attrs.body.myOrg.avatarSection.wrapper}>
          <Avatar {...attrs.body.myOrg.avatarSection.avatar} />
        </div>
        {orgRows}
      </div>
    ) : null;

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.navigation.wrapper}>
        <div {...attrs.navigation.myOrgCell}>My Organization</div>
        <div {...attrs.navigation.myTeamsCell}>My Teams</div>
      </div>
      <div {...attrs.body.wrapper}>
        {myOrganization}
        {teamsTab}
      </div>
    </div>
  );
};
