import React, { useMemo, useState } from 'react';
import {
  Button,
  ButtonSize,
  ChevronDownIcon,
  Dropdown,
} from '../../../../components/SoundWave';
import {
  DateRangeOption,
  getDateRangeOptions,
} from '../../../../utils/getDateRangeDropdownOptions';

export interface DateRangeDropdownProps {
  selectedDateRange: DateRangeOption;
  setSelectedDateRange: React.Dispatch<React.SetStateAction<DateRangeOption>>;
}

export const DateRangeDropdown: React.FC<DateRangeDropdownProps> = ({
  selectedDateRange,
  setSelectedDateRange,
}) => {
  const [isDateDropdownOpened, setIsDateDropdownOpened] = useState(false);

  const dateRangeDropdownOptions = useMemo(
    () =>
      getDateRangeOptions().map(({ label, fromDate, toDate }) => ({
        label,
        className: 'teamq-date-and-team-selector__dropdown-option',
        disabled:
          fromDate === selectedDateRange.fromDate &&
          toDate === selectedDateRange.toDate,
        onClick: () => {
          setIsDateDropdownOpened(false);
          setSelectedDateRange(() => ({ label, fromDate, toDate }));
        },
      })),
    [selectedDateRange, setSelectedDateRange]
  );

  const attrs = {
    dateRangeDropdown: {
      isOpened: isDateDropdownOpened,
      closeOnScroll: true,
      options: dateRangeDropdownOptions,
      onClose: () => {
        setIsDateDropdownOpened(false);
      },
    },
    dateRangeDropdownButton: {
      size: ButtonSize.S,
      secondary: true,
      className: `teamq-date-and-team-selector__button ${
        isDateDropdownOpened
          ? 'teamq-date-and-team-selector__button--opened'
          : ''
      }`,
      onClick: () => {
        setIsDateDropdownOpened(!isDateDropdownOpened);
      },
    },
    dateRangeDropdownButtonIcon: {
      className: 'teamq-date-and-team-selector__button-icon',
    },
  };

  return (
    <Dropdown {...attrs.dateRangeDropdown}>
      <Button {...attrs.dateRangeDropdownButton}>
        <span>{selectedDateRange.label}</span>
        <ChevronDownIcon {...attrs.dateRangeDropdownButtonIcon} />
      </Button>
    </Dropdown>
  );
};
