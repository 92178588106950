import React from 'react';

export const RemoveEngIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00004 1.3335C11.6819 1.3335 14.6667 4.31826 14.6667 8.00016C14.6667 11.6821 11.6819 14.6668 8.00004 14.6668C4.31814 14.6668 1.33337 11.6821 1.33337 8.00016C1.33337 4.31826 4.31814 1.3335 8.00004 1.3335ZM8.00004 2.3335C4.87043 2.3335 2.33337 4.87055 2.33337 8.00016C2.33337 11.1298 4.87043 13.6668 8.00004 13.6668C11.1297 13.6668 13.6667 11.1298 13.6667 8.00016C13.6667 4.87055 11.1297 2.3335 8.00004 2.3335ZM10.8334 7.50016C11.1095 7.50016 11.3334 7.72402 11.3334 8.00016C11.3334 8.27631 11.1095 8.50016 10.8334 8.50016C7.50201 8.50016 8.8074 8.50016 5.16671 8.50016C4.89057 8.50016 4.66671 8.27631 4.66671 8.00016C4.66671 7.72402 4.89057 7.50016 5.16671 7.50016C8.8074 7.50016 7.50201 7.50016 10.8334 7.50016Z"
        fill="white"
      />
    </svg>
  );
};
