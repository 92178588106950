import React, { useEffect, useState } from 'react';
import { Button, ButtonSize } from '../../components/SoundWave';
import { useGetAdminSettingsData, useTrackPageView } from '../../hooks';
import { SubscriptionInfo } from './components/SubscriptionInfo/SubscriptionInfo';
import { ManageSubscriptionModal } from './components/ManageSubscriptionModal/ManageSubscriptionModal';
import { InvoicesList } from './components/InvoicesList/InvoicesList';
import { useAppDispatch } from '../../store/hooks';
import { resetBillingPage } from '../../store/billing/billing.slice';

export const Billing: React.FC = () => {
  const dispatch = useAppDispatch();

  const [
    isManageSubscriptionModalOpened,
    setIsManageSubscriptionModalOpened,
  ] = useState(false);

  const attrs = {
    container: {
      className: 'billing',
    },
    manageSubscriptionModal: {
      isOpened: isManageSubscriptionModalOpened,
      onClose: () => {
        setIsManageSubscriptionModalOpened(false);
      },
    },
    header: {
      className: 'billing__header',
    },
    title: {
      className: 'billing__title',
    },
    manageSubscriptionButton: {
      size: ButtonSize.XS,
      secondary: true,
      onClick: () => {
        setIsManageSubscriptionModalOpened(true);
      },
    },
    content: {
      className: 'billing__content',
    },
    subtitle: {
      className: 'billing__subtitle',
    },
  };

  useGetAdminSettingsData();
  useTrackPageView();

  useEffect(() => {
    return () => {
      dispatch(resetBillingPage());
    };
  }, [dispatch]);

  return (
    <div {...attrs.container}>
      <ManageSubscriptionModal {...attrs.manageSubscriptionModal} />
      <div {...attrs.header}>
        <span {...attrs.title}>Billing</span>
        <Button {...attrs.manageSubscriptionButton}>
          <span>Manage Subscription</span>
        </Button>
      </div>
      <div {...attrs.content}>
        <SubscriptionInfo />
        <div {...attrs.subtitle}>Invoice history</div>
        <InvoicesList />
      </div>
    </div>
  );
};
