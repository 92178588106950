import React from 'react';
import { ContactItem } from '../../../../../../components/AddMemberModal/components/ContactItem/ContactItem';
import {
  Modal,
  EditIcon,
  Avatar,
  AvatarSize,
  Button,
  ButtonSize,
} from '../../../../../../components/SoundWave';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { openAddMemberModal } from '../../../../../../store/relationshipFlows/relationshipFlows.slice';
import { Contact } from '../../../../../../types/contact.types';
import { RelationshipMemberAssociationType } from '../../../../../../types/relationshipMember.types';
import {
  getAssignedToString,
  getContactTypeString,
  getPhoneNumberString,
} from './MemberContactModal.utils';

const ASSOCIATED_ACCOUNTS_TO_SHOW = 2;

export interface MemberContactModalProps {
  isOpened: boolean;
  contact: Contact;
  onClose: () => void;
}

export const MemberContactModal: React.FC<MemberContactModalProps> = ({
  isOpened,
  contact,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const userOrganization = useAppSelector((state) => state.auth.org);

  const prospectAccount = useAppSelector(
    (state) => state.relationshipDetails.account
  );

  const phoneNumber = getPhoneNumberString(contact);

  const assignedTo = getAssignedToString(
    contact,
    prospectAccount?.name,
    userOrganization?.name
  );

  const contactType = getContactTypeString(contact);

  const { linkedAccounts } = contact?.extra || {};

  const attrs = {
    modal: {
      isOpened,
      onClose,
      closeOnClickOutside: true,
      width: 364,
      headerProps: {
        title: 'Member Contact Details',
      },
    },
    content: {
      className: 'member-contact',
    },
    header: {
      className: 'member-contact__header',
    },
    headerAvatarWrapper: {
      className: 'member-contact__header-avatar-wrapper',
    },
    headerAvatar: {
      src: contact?.metadata?.avatarSrc || '',
      name: contact?.metadata?.name || contact?.email || '',
      size: AvatarSize.L,
    },
    headerTextWrapper: {
      className: 'member-contact__header-text-wrapper',
    },
    headerName: {
      className: 'member-contact__header-name',
    },
    headerTitle: {
      className: 'member-contact__header-title',
    },
    editButton: {
      size: ButtonSize.XS,
      secondary: true,
      'aria-label': 'Edit member',
      className: 'member-contact__edit-button',
      onClick: () => {
        if (prospectAccount && contact) {
          dispatch(
            openAddMemberModal({
              accountId: prospectAccount.accountId,
              accountName: prospectAccount.name,
              isEditFlow: true,
              existingContact: contact,
              initialValues: {
                email: contact.email || '',
                firstName: contact.metadata.firstName || '',
                lastName: contact.metadata.lastName || '',
                title: contact.metadata.title || '',
                phone: contact.metadata?.phoneNumbers?.[0]?.number || '',
                countryCode:
                  contact.metadata?.phoneNumbers?.[0]?.countryCode || '',
                assignedTo:
                  contact.extra?.currentAccountLink?.contactSide || '',
                contactType:
                  contact.extra?.currentAccountLink?.associationType || '',
                avatar: contact.metadata.avatarSrc || '',
                note: contact.extra?.currentAccountLink?.note || '',
              },
            })
          );
        }
      },
    },
    item: {
      className: 'member-contact__item',
    },
    itemLabel: {
      className: 'member-contact__item-label',
    },
    itemValue: {
      className: 'member-contact__item-value',
    },
    associatedAccounts: {
      className: 'member-contact__associated-accounts',
    },
    accountsAmount: {
      className: 'member-contact__accounts-amount',
    },
  };

  const renderAssociatedAccounts = () =>
    linkedAccounts
      ?.slice(0, ASSOCIATED_ACCOUNTS_TO_SHOW)
      .map(({ account, link }) => {
        const props = {
          key: account.accountId,
          isAccount: true,
          showSecondaryText: true,
          text: account.name,
          ...(link.associationType && {
            secondaryText:
              link.associationType ===
              RelationshipMemberAssociationType.TEAM_MEMBER
                ? 'Team member'
                : '3rd Party',
          }),
        };

        return <ContactItem {...props} />;
      });

  return (
    <Modal {...attrs.modal}>
      <div {...attrs.content}>
        <div {...attrs.header}>
          <div {...attrs.headerAvatarWrapper}>
            <Avatar {...attrs.headerAvatar} />
          </div>
          <div {...attrs.headerTextWrapper}>
            <span {...attrs.headerName}>
              {contact?.metadata?.name || contact?.email || ''}
            </span>
            <span {...attrs.headerTitle}>{contact?.metadata?.title || ''}</span>
          </div>
          <Button {...attrs.editButton}>
            <EditIcon />
          </Button>
        </div>
        <div {...attrs.item}>
          <div {...attrs.itemLabel}>Email</div>
          <div {...attrs.itemValue}>{contact?.email || '-'}</div>
        </div>
        <div {...attrs.item}>
          <div {...attrs.itemLabel}>Telephone</div>
          <div {...attrs.itemValue}>{phoneNumber}</div>
        </div>
        <div {...attrs.item}>
          <div {...attrs.itemLabel}>Assigned</div>
          <div {...attrs.itemValue}>{assignedTo}</div>
        </div>
        <div {...attrs.item}>
          <div {...attrs.itemLabel}>Contact Type</div>
          <div {...attrs.itemValue}>{contactType}</div>
        </div>
        <div {...attrs.item}>
          <div {...attrs.itemLabel}>Note</div>
          <div {...attrs.itemValue}>
            {contact?.extra?.currentAccountLink?.note || '-'}
          </div>
        </div>
        {linkedAccounts?.length ? (
          <div {...attrs.item}>
            <div {...attrs.itemLabel}>Assotiated to relationships</div>
            <div {...attrs.itemValue}>
              <div {...attrs.associatedAccounts}>
                {renderAssociatedAccounts()}
                {linkedAccounts.length > ASSOCIATED_ACCOUNTS_TO_SHOW ? (
                  <div {...attrs.accountsAmount}>
                    +{linkedAccounts.length - ASSOCIATED_ACCOUNTS_TO_SHOW}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};
