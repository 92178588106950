import { useCallback, useContext } from 'react';
import { match as RouterMatch } from 'react-router-dom';
import { PATHS } from '../constants/paths';
import { MixpanelContext } from '../context';
import { useAppSelector } from '../store/hooks';
import { useCurrentPathMatch } from './useCurrentPathMatch';

const getPageName = (match: RouterMatch | null) => {
  const pageNameMap = {
    [PATHS.RELATIONSHIPS]: 'relationships',
    [PATHS.THREAD_DETAILS]: 'threadDetails',
    [PATHS.PERFORMACE_Q]: 'performanceQ',
    [PATHS.RELATIONSHIP_DETAILS]: 'relationshipDetails',
    [PATHS.MEETING_RECORDS]: 'meetings',
    [PATHS.MEETING_DETAILS]: 'meetingDetails',
    [PATHS.MEETING_RECORD]: 'meetingDetails',
    [PATHS.ADMIN_SETTINGS_APPS]: 'apps',
    [PATHS.APPS]: 'apps',
    [PATHS.ADMIN_SETTINGS_TEAMS]: 'teams',
    [PATHS.ADMIN_SETTINGS_ORGANIZATION]: 'organization',
    [PATHS.ORGANIZATION_DETAILS]: 'organization',
    [PATHS.ACCOUNT]: 'account',
    [PATHS.ADMIN_SETTINGS_USERS]: 'users',
    [PATHS.USERS]: 'users',
    [PATHS.ADMIN_SETTINGS_BILLING]: 'billing',
  };

  if (match?.path === PATHS.TEAM_ANALYTICS) {
    if (match.url.includes('overview')) {
      return 'teamQOverview';
    }

    if (match.url.includes('activity')) {
      return 'teamQActivity';
    }

    return 'teamQAnalytics';
  }

  return match?.path ? pageNameMap[match.path] || '' : '';
};

export const useMixpanel = (): {
  trackEvent: (action: string, properties?: Record<string, any>) => void;
} => {
  const isInitialized = useAppSelector(
    (state) => state.auth.isMixPanelInitialized
  );

  const { mixpanelInstance } = useContext(MixpanelContext);

  const currentMatch = useCurrentPathMatch();

  const trackEvent = useCallback(
    (action: string, properties?: Record<string, any>) => {
      const pageName = getPageName(currentMatch);

      if (isInitialized && pageName && action) {
        mixpanelInstance.track(`dashboard.${pageName}.${action}`, properties);
      }
    },
    [currentMatch, isInitialized, mixpanelInstance]
  );

  return {
    trackEvent,
  };
};
