import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from '../../axios';
import { MeetingRecord } from '../../types/meetingDetails.types';
import { ApiResponseData } from '../../types/apiResponse.types';
import { Pagination } from '../../types/pagination.types';

export const getMeetingRecords = createAsyncThunk<
  Pagination & {
    results: MeetingRecord[];
  }
>(
  'meetingRecords/getMeetingRecords',
  async (_, { getState, fulfillWithValue, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
        meetingRecords: {
          pagination: { ipp, p, sortBy, sortOrder },
        },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.get<
        ApiResponseData<
          Pagination & {
            results: MeetingRecord[];
          }
        >
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/online-meetings`,
        {
          signal,
          params: {
            ipp,
            p: p + 1,
            ...(sortBy && { sortBy }),
            ...(sortOrder && { sortOrder }),
          },
        }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);
