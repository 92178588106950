import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getSubscriptionStatus } from '../../store/auth/auth.actions';
import { Modal } from '../Modal/Modal';
import { SIGNUP_LINK } from '../../constants/config';

type ModalType = 'trialEnded' | 'subscriptionExpired';

export const NonPaidUserModal: React.FC = () => {
  const { user, org, subscriptionInfo } = useSelector(
    (state: RootState) => state.auth
  );

  const [showModal, setShowModal] = useState(false);

  const location = useLocation();

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      org &&
      org?.signupType === 'selfSignup' &&
      !org.isFunctionalityEnabled
    ) {
      if (!subscriptionInfo) {
        dispatch(getSubscriptionStatus(org.orgId));
      } else {
        setShowModal(true);
      }
    }
  }, [org, dispatch, subscriptionInfo]);

  useEffect(() => {
    if (showModal && !location.pathname.includes('/enagagements')) {
      const engagementsPath = `/orgs/${user?.orgId}/users/${user?.orgId}/engagements`;
      history.push(engagementsPath);
    }
    // eslint-disable-next-line
  }, [showModal]);

  const compareDates = () => {
    let currentCase: ModalType = 'subscriptionExpired';

    if (subscriptionInfo?.trialEnd && subscriptionInfo?.expiredAt) {
      const trialEndedDate = moment(subscriptionInfo.trialEnd);
      const subscriptionExpiredDate = moment(subscriptionInfo.expiredAt);

      if (trialEndedDate === subscriptionExpiredDate) {
        currentCase = 'trialEnded';
      }
    }

    return currentCase;
  };

  const modalType = compareDates();

  const onButtonClick = () => {
    if (user?.role === 'owner') {
      window.location.href = `${SIGNUP_LINK}/signup/pricing`;
    } else {
      window.location.href = 'https://www.substrata.me/';
    }
  };

  const attrs = {
    Modal: {
      open: showModal,
      title: '',
      onClose: () => {},
      empty: true,
      className: 'non-paid-modal',
      zIndex: 1000,
    },

    body: {
      wrapper: {
        className: 'non-paid-modal__body',
      },

      title: {
        className: 'non-paid-modal__body-title',
      },

      message: {
        className: 'non-paid-modal__body-message',
      },

      button: {
        className: 'non-paid-modal__body-button',
        onClick: onButtonClick,
      },
    },
  };

  const title =
    modalType === 'trialEnded'
      ? 'Your trial has ended'
      : 'No active subscription';

  const message =
    user?.role === 'owner'
      ? 'You don’t seem to have an active subscription. Hit the Continue button to view plans and pricing'
      : 'You don’t seem to have an active subscription. Contact your organization owner to activate your subscription.';

  const buttonLabel = user?.role === 'owner' ? 'Continue' : 'Go to homepage';

  return (
    <Modal {...attrs.Modal}>
      <div {...attrs.body.wrapper}>
        <div {...attrs.body.title}>{title}</div>
        <div {...attrs.body.message}>{message}</div>
        <div {...attrs.body.button}>{buttonLabel}</div>
      </div>
    </Modal>
  );
};
