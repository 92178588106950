import moment from 'moment';
import { InviteStatus, Row, RowUserStatus, User } from '../../types';

const getInviteStatus = (
  inviteExpires: string,
  inviteStatus: InviteStatus
): InviteStatus | undefined => {
  if (moment().isAfter(inviteExpires) && inviteStatus !== 'accepted')
    return 'expired';
  return inviteStatus;
};

const defineUserStatus = (
  activeStatus: boolean,
  inviteExpires: string,
  inviteStatus: InviteStatus
): RowUserStatus | undefined => {
  let currentStatus: RowUserStatus | undefined;

  const currInviteStatus = getInviteStatus(inviteExpires, inviteStatus);

  if (currInviteStatus === 'sent') {
    currentStatus = 'sent';
  } else if (currInviteStatus === 'expired') {
    currentStatus = 'expired';
  } else if (currInviteStatus === 'accepted') {
    if (activeStatus) {
      currentStatus = 'active';
    } else {
      currentStatus = 'inactive';
    }
  }

  return currentStatus;
};

export const getUserRow = (user: User): Row => ({
  ...user,
  name: `${user.firstName} ${user.lastName}`,
  userId: user.userId,
  key: user.userId,
  status: defineUserStatus(user.active, user.inviteExpires, user.inviteStatus),
  options: user,
  emailsAnalyzed:
    user.apps.find((app) => app.name === 'email-analyzer')?.stats
      ?.emailsAnalyzed || 0,
  invitationSent: getInviteStatus(user.inviteExpires, user.inviteStatus),
});

export const sortUsersArray = (usersArray: User[]): Row[] => {
  const result: User[] = [];
  const superAdmins = usersArray.filter((user) => user?.role === 'superAdmin');
  const owner = usersArray.filter((user) => user?.role === 'owner');
  const admins = usersArray.filter((user) => user?.role === 'admin');
  const dealmakers = usersArray
    .filter((user) => user?.role === 'dealmaker')
    .sort((a, b) => (a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : -1));
  return result
    .concat(superAdmins, owner, admins, dealmakers)
    .map((user) => getUserRow(user));
};
