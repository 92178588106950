import React from 'react';
import { LoaderIcon } from '../../../../../Icons/LoaderIcon';
import { Button, ButtonSize } from '../../../Button/Button';

export interface ModalFooterProps {
  cancelButtonText?: string;
  confirmButtonText?: string;
  onCancel: () => void;
  formId?: string;
  isLoading?: boolean;
  isCancelButtonDisabled?: boolean;
  isCancelButtonHidden?: boolean;
  isConfirmButtonDisabled?: boolean;
  isConfirmButtonSecondary?: boolean;
  isConfirmButtonDanger?: boolean;
  buttonsSize?: ButtonSize;
  onConfirm?: () => void;
}

export const ModalFooter: React.FC<ModalFooterProps> = ({
  cancelButtonText,
  confirmButtonText,
  formId,
  isLoading = false,
  isCancelButtonDisabled = false,
  isCancelButtonHidden = false,
  isConfirmButtonDisabled = false,
  isConfirmButtonSecondary = false,
  isConfirmButtonDanger = false,
  buttonsSize = ButtonSize.S,
  onCancel,
  onConfirm,
}) => {
  const attrs = {
    container: {
      className: 'sound-wave-modal-footer',
    },
    cancelButton: {
      size: buttonsSize,
      secondary: true,
      onClick: onCancel,
      disabled: isLoading || isCancelButtonDisabled,
    },
    confirmButton: {
      size: buttonsSize,
      type: formId ? ('submit' as const) : ('button' as const),
      secondary: isConfirmButtonSecondary,
      danger: isConfirmButtonDanger,
      className: `sound-wave-modal-footer__button${
        isLoading ? ' sound-wave-modal-footer__button--loading' : ''
      }${
        isCancelButtonHidden ? ' sound-wave-modal-footer__button--ml-auto' : ''
      }`,
      onClick: onConfirm,
      disabled: isLoading || isConfirmButtonDisabled,
      ...(formId ? { form: formId } : {}),
    },
    buttonLoader: {
      className: 'sound-wave-modal-footer__button-loader',
    },
  };

  return (
    <div {...attrs.container}>
      {!isCancelButtonHidden ? (
        <Button {...attrs.cancelButton}>
          <span>{cancelButtonText}</span>
        </Button>
      ) : null}
      <Button {...attrs.confirmButton}>
        <span>{confirmButtonText}</span>
        {isLoading ? <LoaderIcon {...attrs.buttonLoader} /> : null}
      </Button>
    </div>
  );
};
