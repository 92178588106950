import React, { useLayoutEffect } from 'react';
import { AxiosRequestConfig } from 'axios';
import { useAppDispatch } from '../../store/hooks';
import { switchToMaintenanceMode } from '../../store/global/global.slice';
import axios from '../../axios';
import { StatusCode } from '../../types/statusCode.types';
import { refreshToken } from '../../store/auth/auth.actions';

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

export const ClientInterceptors: React.FC = () => {
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    const retryQueue: RetryQueueItem[] = [];
    let isRefreshingToken = false;

    axios.interceptors.request.use(async (config) => {
      const token = localStorage.getItem('token');

      if (config.headers && token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const { retry, ...originalRequest } = error.config;

        if (error.response?.status === StatusCode.Unauthorized && !retry) {
          if (!isRefreshingToken) {
            isRefreshingToken = true;

            try {
              await refreshToken()(dispatch);

              retryQueue.forEach(({ config, resolve, reject }) => {
                axios.request(config).then(resolve).catch(reject);
              });

              retryQueue.length = 0;

              // eslint-disable-next-line @typescript-eslint/return-await
              return axios(originalRequest);
            } catch (e) {
              // eslint-disable-next-line @typescript-eslint/return-await
              return Promise.reject(e);
            } finally {
              isRefreshingToken = false;
            }
          }

          return new Promise<void>((resolve, reject) => {
            retryQueue.push({ config: originalRequest, resolve, reject });
          });
        }

        if (error.response?.status === StatusCode.ServiceUnavailable) {
          dispatch(switchToMaintenanceMode());
        }

        return Promise.reject(error);
      }
    );
  }, [dispatch]);

  return null;
};
