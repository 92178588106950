import React from 'react';
import { Button, ButtonSize } from '../../../../../../components/SoundWave';
import { useMixpanel } from '../../../../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  switchToEditMode,
  switchToViewMode,
} from '../../../../../../store/relationshipDetails/relationshipDetails.slice';
import { openAddMemberModal } from '../../../../../../store/relationshipFlows/relationshipFlows.slice';
import { PlusIcon } from '../../icons/PlusIcon';
import { HubspotIcon } from './icons/HubspotIcon';

export const RsDetailsControlsRow: React.FC = () => {
  const dispatch = useAppDispatch();

  const account = useAppSelector((state) => state.relationshipDetails.account);

  const isEditMode = useAppSelector(
    (state) => state.relationshipDetails.isEditMode
  );

  const { trackEvent } = useMixpanel();

  const attrs = {
    wrapper: {
      className: 'controls-row',
    },

    leftSection: {
      wrapper: {
        className: 'controls-row__left-section',
      },

      buttons: {
        addMember: {
          size: ButtonSize.XS,
          secondary: true,
          onClick: () => {
            if (account) {
              dispatch(
                openAddMemberModal({
                  accountId: account.accountId,
                  accountName: account.name,
                })
              );
            }
          },
        },
        connectCrm: {
          size: ButtonSize.XS,
          secondary: true,
          disabled: true,
        },
      },
    },

    rightSection: {
      wrapper: {
        className: 'controls-row__right-section',
      },

      controls: {
        wrapper: {
          className: 'controls-row__right-section__controls',
        },

        view: {
          className: `${
            !isEditMode ? 'active' : ''
          } controls-row__right-section__controls-button`,
          type: 'button' as const,
          disabled: !isEditMode,
          onClick: () => {
            if (isEditMode) {
              trackEvent('switchToViewMode');
              dispatch(switchToViewMode());
            }
          },
        },

        edit: {
          className: `${
            isEditMode ? 'active' : ''
          } controls-row__right-section__controls-button`,
          type: 'button' as const,
          disabled: isEditMode,
          onClick: () => {
            if (!isEditMode) {
              trackEvent('switchToEditMode');
              dispatch(switchToEditMode());
            }
          },
        },
      },
    },
  };

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.leftSection.wrapper}>
        <Button {...attrs.leftSection.buttons.addMember}>
          <PlusIcon />
          <span>Add Member</span>
        </Button>
        <Button {...attrs.leftSection.buttons.connectCrm}>
          <HubspotIcon />
          <span>Connect</span>
        </Button>
      </div>
      <div {...attrs.rightSection.wrapper}>
        <div {...attrs.rightSection.controls.wrapper}>
          <button {...attrs.rightSection.controls.view}>View</button>
          <button {...attrs.rightSection.controls.edit}>Edit</button>
        </div>
      </div>
    </div>
  );
};
