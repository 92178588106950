import React from 'react';
import { Skeleton } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ChevronLeftIcon } from './icons/ChevronLeftIcon';
import { ChevronRightIcon } from './icons/ChevronRightIcon';
import { useAppSelector } from '../../store/hooks';
import { PATHS } from '../../constants/paths';
import { getIsLoading } from './utils/getIsLoading';
import { getEventName } from './utils/getEventName';
import { getEventNavigationUrls } from './utils/getEventNavigationUrls';
import { Button, ButtonSize } from '../SoundWave';

export const EventPagination: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);

  const isMeetingDetailsLoading = useAppSelector(
    (state) => state.meetingDetails.isLoading.meetingDetails
  );

  const isMeetingDetailsError = useAppSelector(
    (state) => state.meetingDetails.isError.meetingDetails
  );

  const isThreadDetailsLoading = useAppSelector(
    (state) => state.threadDetails.isLoading.threadDetails
  );

  const isThreadDetailsError = useAppSelector(
    (state) => state.threadDetails.isError.threadDetails
  );

  const meetingDetails = useAppSelector((state) => state.meetingDetails.data);

  const threadDetails = useAppSelector((state) => state.threadDetails);

  const meetingDetailsMatch = useRouteMatch(PATHS.MEETING_DETAILS);
  const threadDetailsMatch = useRouteMatch(PATHS.THREAD_DETAILS);
  const touchPointDetailsMatch = useRouteMatch(PATHS.TOUCH_POINT_DETAILS);

  const history = useHistory();

  const isLoading = getIsLoading({
    isMeetingDetailsLoading,
    isMeetingDetailsError,
    isThreadDetailsLoading,
    isThreadDetailsError,
    isMeetingDetailsPage: !!meetingDetailsMatch,
    isThreadDetailsPage: !!threadDetailsMatch || !!touchPointDetailsMatch,
  });

  const eventName = getEventName({
    isLoading,
    meetingTitle: meetingDetails?.title,
    threadSubject: threadDetails.analysis?.branch?.subject,
    isMeetingDetailsPage: !!meetingDetailsMatch,
    isThreadDetailsPage: !!threadDetailsMatch || !!touchPointDetailsMatch,
  });

  const { prevEventUrl, nextEventUrl } = getEventNavigationUrls({
    user,
    meetingDetails,
    threadDetails,
    isMeetingDetailsPage: !!meetingDetailsMatch,
    isThreadDetailsPage: !!threadDetailsMatch || !!touchPointDetailsMatch,
  });

  const attrs = {
    container: {
      className: 'event-pagination',
    },
    previousButton: {
      size: ButtonSize.XS,
      secondary: true,
      transparent: true,
      className: 'event-pagination__button',
      disabled: isLoading || !prevEventUrl,
      onClick: () => {
        if (prevEventUrl) {
          history.push(prevEventUrl);
        }
      },
    },
    nextButton: {
      size: ButtonSize.XS,
      secondary: true,
      transparent: true,
      className: 'event-pagination__button',
      disabled: isLoading || !nextEventUrl,
      onClick: () => {
        if (nextEventUrl) {
          history.push(nextEventUrl);
        }
      },
    },
    eventName: {
      className: 'event-pagination__event-name',
    },
    eventNameSkeleton: {
      active: true,
      paragraph: false,
      style: {
        width: 250,
      },
      title: {
        width: 250,
        style: {
          width: 250,
          height: 16,
          borderRadius: 4,
          margin: 0,
        },
      },
    },
  };

  return (
    <div {...attrs.container}>
      <Button {...attrs.previousButton}>
        <ChevronLeftIcon />
        <span>Previous</span>
      </Button>
      {isLoading ? (
        <Skeleton {...attrs.eventNameSkeleton} />
      ) : (
        <span {...attrs.eventName}>{eventName}</span>
      )}
      <Button {...attrs.nextButton}>
        <span>Next</span>
        <ChevronRightIcon />
      </Button>
    </div>
  );
};
