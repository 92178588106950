import React, { useEffect, useRef } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { SellingZoneElement } from '@substratahq/selling-zone/typing/base';
import { EventFrame } from '../../components';
import { EmailList } from './components/EmailsList/EmailsList';
import { EmailAnalysis } from './components/EmailAnalysis/EmailAnalysis';
import { ThreadOverview } from './components/ThreadOverview/ThreadOverview';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { SellingZone } from './components/SellingZone/SellingZone';
import { EmailEventIcon } from '../../components/Icons/EmailEventIcon';
import { clearThreadDetails } from '../../store/threadDetails/threadDetails.slice';
import { getThreadDetails } from '../../store/threadDetails/threadDetails.thunks';
import {
  useGetRelationshipAccountDetails,
  useTrackPageView,
} from '../../hooks';
import { TooltipPosition } from '../../components/SoundWave';
import { EventPagination } from '../../components/EventPagination/EventPagination';
import { PATHS } from '../../constants/paths';

export const ThreadDetails: React.FC = () => {
  const dispatch = useAppDispatch();

  const isDetailedAnalysisLoading = useAppSelector(
    (state) => state.threadDetails.isLoading.threadDetails
  );

  const detailedAnalysis = useAppSelector(
    (state) => state.threadDetails.analysis
  );

  const touchPoint = useAppSelector((state) => state.threadDetails.touchPoint);

  const account = useAppSelector((state) => state.threadDetails.account);

  const user = useAppSelector((state) => state.auth.user);

  const selectedEmail = useAppSelector(
    (state) => state.threadDetails.selectedEmail
  );

  const history = useHistory();

  const touchPointDetailsMatch = useRouteMatch(PATHS.TOUCH_POINT_DETAILS);

  const { threadId } = useParams<{
    threadId: string;
  }>();

  const sellingZoneRef = useRef<SellingZoneElement | null>(null);

  const { platformMessageId = '' } = selectedEmail || {};

  const isDifferentThreadOwner =
    !!touchPoint && !!user && touchPoint.userOwnerId !== user.userId;

  const attrs = {
    container: {
      className: 'thread-details',
    },
    sellingZoneWrapper: {
      className: 'thread-details__selling-zone-wrapper',
    },
    sellingZone: {
      sellingZoneRef,
    },
    eventFrame: {
      headerProps: {
        isLoading: isDetailedAnalysisLoading,
        leftIcon: EmailEventIcon,
        titleProps: {
          value: detailedAnalysis?.branch?.subject || '',
        },
        buttonProps: {
          text: 'Open in inbox',
          disabled: isDifferentThreadOwner,
          onClick: () => {
            window.open(
              `https://mail.google.com/mail/u/${user?.email}/#inbox/${platformMessageId}`,
              '_blank',
              'noopener noreferrer'
            );
          },
          ...(isDifferentThreadOwner && {
            tooltipProps: {
              simplified: true,
              width: 262,
              position: TooltipPosition.TOP_END,
              text: 'You are not the participant of this thread',
            },
          }),
        },
      },
      leftContent: <EmailList sellingZoneRef={sellingZoneRef} />,
      rightContent: (
        <>
          <ThreadOverview />
          <EmailAnalysis />
        </>
      ),
    },
  };

  useGetRelationshipAccountDetails();
  useTrackPageView([threadId]);

  useEffect(() => {
    const request = dispatch(getThreadDetails(threadId));

    return () => {
      request.abort();
      dispatch(clearThreadDetails());
    };
  }, [threadId, dispatch]);

  useEffect(() => {
    if (account && touchPointDetailsMatch) {
      history.replace(
        `/orgs/${user?.orgId}/users/${user?.userId}/relationships/${account.accountId}/threads/${threadId}`
      );
    }
  }, [account, history, touchPointDetailsMatch, threadId, user]);

  return (
    <div {...attrs.container}>
      <div {...attrs.sellingZoneWrapper}>
        <SellingZone {...attrs.sellingZone} />
      </div>
      <EventFrame {...attrs.eventFrame} />
      <EventPagination />
    </div>
  );
};
