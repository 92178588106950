import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SellingZoneGraphItem } from '@substratahq/selling-zone/typing/base';
import { EmailBranch } from '../../types/emailAnalysis.types';
import { getThreadDetails } from './threadDetails.thunks';
import { ThreadDetailsState } from './threadDetails.types';

export const initialState: ThreadDetailsState = {
  isLoading: {
    threadDetails: false,
  },
  isError: {
    threadDetails: false,
  },
  touchPoint: null,
  account: null,
  contacts: [],
  analysis: null,
  selectedBranch: null,
  selectedEmail: null,
  selectedGraphItem: null,
  prevTouchPoint: null,
  nextTouchPoint: null,
  graphItems: [],
};

const threadDetailsSlice = createSlice({
  name: 'threadDetails',
  initialState,
  reducers: {
    updateCurrentBranch: (state, action: PayloadAction<EmailBranch>) => {
      state.selectedBranch = action.payload;
    },
    updateCurrentEmail: (state, action: PayloadAction<string>) => {
      state.selectedEmail = state.selectedBranch?.emails
        ? state.selectedBranch.emails.find(
            (email) => email.platformMessageId === action.payload
          ) || null
        : null;
    },
    updateGraphItems: (
      state,
      action: PayloadAction<SellingZoneGraphItem[]>
    ) => {
      state.graphItems = action.payload;
    },
    updateSelectedGraphItem: (state, action: PayloadAction<number>) => {
      state.selectedGraphItem = state?.graphItems?.length
        ? state.graphItems.find((item) => item.id === action.payload) || null
        : null;
    },
    clearThreadDetails: (state) => {
      state.touchPoint = null;
      state.account = null;
      state.contacts = [];
      state.analysis = null;
      state.selectedBranch = null;
      state.selectedEmail = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getThreadDetails.pending, (state) => {
      state.isLoading.threadDetails = true;
      state.isError.threadDetails = false;
    });
    builder.addCase(getThreadDetails.fulfilled, (state, action) => {
      state.isLoading.threadDetails = false;
      state.touchPoint = action.payload.touchPoint;
      state.account = action.payload.account;
      state.contacts = action.payload.contacts || [];
      state.analysis = action.payload.analysis;
      state.prevTouchPoint = action.payload.prevTouchPoint;
      state.nextTouchPoint = action.payload.nextTouchPoint;
    });
    builder.addCase(getThreadDetails.rejected, (state, action) => {
      state.isLoading.threadDetails = false;
      state.isError.threadDetails = !action.meta.aborted;
    });
  },
});

export const {
  updateCurrentBranch,
  updateCurrentEmail,
  updateGraphItems,
  updateSelectedGraphItem,
  clearThreadDetails,
} = threadDetailsSlice.actions;

export default threadDetailsSlice.reducer;
