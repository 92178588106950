import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { callGoogleCallback } from '../../store/auth/auth.actions';
import { RootState } from '../../store/store';

export const GoogleCallback: React.FC = (): JSX.Element => {
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, org, authError } = useSelector(
    (state: RootState) => state.auth
  );

  useEffect(() => {
    if (!user || !org) dispatch(callGoogleCallback(search));
    if ((user && org) || authError.google) history.push(`/`);
  }, [dispatch, search, history, org, user, authError]);
  return <div />;
};
