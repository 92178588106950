import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeetingRecordsState } from './meetingRecords.type';
import { getMeetingRecords } from './meetingRecords.thunks';
import { assignEventToAccount } from '../relationshipFlows/relationshipFlows.thunks';
import { PaginationSortOrder } from '../../types/pagination.types';

export const initialState: MeetingRecordsState = {
  isLoading: {
    meetingRecords: false,
  },
  isError: {
    meetingRecords: false,
  },
  pagination: {
    count: 0,
    ipp: 20,
    p: 0,
    nextPage: null,
    sortOrder: null,
    sortBy: null,
  },
  data: [],
};

const meetingRecordsSlice = createSlice({
  name: 'meetingRecords',
  initialState,
  reducers: {
    updateSorting: (
      state,
      action: PayloadAction<{
        sortOrder: PaginationSortOrder | null;
        sortBy: string | null;
      }>
    ) => {
      state.data = [];
      state.pagination = {
        ...initialState.pagination,
        sortOrder: action.payload.sortOrder,
        sortBy: action.payload.sortBy,
      };
    },
    resetMeetingRecords: (state) => {
      state.data = [];
      state.pagination = initialState.pagination;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMeetingRecords.pending, (state) => {
      state.isLoading.meetingRecords = true;
      state.isError.meetingRecords = false;
    });
    builder.addCase(getMeetingRecords.fulfilled, (state, action) => {
      state.isLoading.meetingRecords = false;
      state.data = [...state.data, ...action.payload.results];
      state.pagination.ipp = action.payload.ipp;
      state.pagination.p = action.payload.p;
      state.pagination.count = action.payload.results.length;
      state.pagination.nextPage = action.payload.nextPage;
    });
    builder.addCase(getMeetingRecords.rejected, (state, action) => {
      state.isLoading.meetingRecords = action.meta.aborted;
      state.isError.meetingRecords = !action.meta.aborted;
    });
    builder.addCase(assignEventToAccount.fulfilled, (state, action) => {
      state.data = state.data.map((record) =>
        record.touchPointId === action.payload.touchPoint?.touchPointId
          ? { ...record, account: action.payload.account || null }
          : record
      );
    });
  },
});

export const {
  updateSorting,
  resetMeetingRecords,
} = meetingRecordsSlice.actions;

export default meetingRecordsSlice.reducer;
