import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';

import './App.scss';
import { AppRouting } from './AppRouting';
import { store } from './store/store';
import { Login } from './pages/Login/Login';
import { NotFound } from './pages/NotFound/NotFound';
import '@substratahq/selling-zone';
import { INTERCOM_APP_ID } from './constants/config';
import { SvgIcons } from './components/Icons/SvgIcons';
import { ClientInterceptors } from './components';
import { MixpanelProvider } from './context';

// dG9rOjM4NGE0ZTdlXzMyYmVfNDUzZV84NmMyX2YwZWM1MjZmNGNhYjoxOjA=

const App: React.FC = (): JSX.Element => {
  const intercomProviderProps = {
    appId: INTERCOM_APP_ID,
    autoBoot: true,
    autoBootProps: {
      verticalPadding: 20,
      horisontalPadding: 20,
    },
  };

  return (
    <Provider store={store}>
      <ClientInterceptors />
      <Router>
        <Switch>
          <Route exact path="/">
            <IntercomProvider {...intercomProviderProps}>
              <Login />
            </IntercomProvider>
          </Route>
          <Route path="/not-found">
            <NotFound />
          </Route>
          <Route exact>
            <SvgIcons />
            <IntercomProvider {...intercomProviderProps}>
              <MixpanelProvider>
                <AppRouting />
              </MixpanelProvider>
            </IntercomProvider>
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
