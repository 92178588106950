import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { ActiveStatus } from '../../../../components';
import { PopupOption } from '../../../../components/Popup/PopupOption';
import {
  clearUsers,
  closeDeleteUserModal,
  getUsers,
  invokeAssignRoleModal,
  invokeDeleteModal,
  resendInvite,
  setCurrentUser,
} from '../../../../store/users/users.actions';
import { RootState } from '../../../../store/store';
import { Options } from './components/Options';
import { Row, User } from '../../../../types';
import { TableRowName } from './components/TableRowName';
import { EmailsAnalyzed } from './components/EmailsAnalyzed';
import { PencilPopupIcon } from './icons/PencilPopupIcon';
import { ResendInvitePopupIcon } from './icons/ResendInvitePopupIcon';
import { AssignRolePopupIcon } from './icons/AssignRolePopupIcon';
import { DeleteUserPopupIcon } from './icons/DeleteUserPopupIcon';

const noop = () => {};

const statusTitle = <div className="centered table__column-title">Status</div>;

type Col = {
  title: string | JSX.Element;
  dataIndex: string;
  key: string; // eslint-disable-next-line
  render?: (...params: any[]) => any;
  shouldCellUpdate: () => boolean;
};

const getCols = (
  options: JSX.Element[],
  getSelectedRow: (user: User) => void
): Col[] => [
  {
    title: 'Name',
    dataIndex: 'userId',
    key: 'name',
    render: (userId: string): JSX.Element => <TableRowName userId={userId} />,
    shouldCellUpdate: () => true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    shouldCellUpdate: () => true,
  },
  {
    title: statusTitle,
    dataIndex: 'status',
    key: 'status',
    render: (
      status: 'active' | 'inactive' | 'sent' | 'expired'
    ): JSX.Element => <ActiveStatus status={status} centered />,
    shouldCellUpdate: () => true,
  },
  {
    title: 'Last Activity',
    dataIndex: 'lastActivity',
    key: 'lastActivity',
    render: (lastActivity: string) =>
      lastActivity ? moment(lastActivity).fromNow() : '',
    shouldCellUpdate: () => true,
  },
  {
    title: 'Emails Analyzed',
    dataIndex: 'emailsAnalyzed',
    key: 'emailsAnalyzed',
    render: (emailsNumber: number) => (
      <EmailsAnalyzed emailsNumber={emailsNumber} />
    ),
    shouldCellUpdate: () => true,
  },
  {
    title: '',
    dataIndex: 'options',
    key: 'options',
    render: (user: User): JSX.Element => (
      <div onClick={() => getSelectedRow(user)}>
        <Options options={options} />
      </div>
    ),
    shouldCellUpdate: () => true,
  },
];

type UsersTable = {
  cols: Col[];
  users: Row[];
  rowSelection: {
    onChange: (keys: React.Key[], rows: Row[]) => void;
  };
  selectedRows: Row[];
  invokeModal: () => void;
  deleteModalOpen: boolean;
  closeDeleteModal: () => void;
  assignRoleModalOpen: boolean;
  currentUser: User | null;
  orgId: string;
  toggleAssignRoleModal: () => void;
  resentInvite: boolean;
  closeResentInviteModal: () => void;
  user: User | null;
  usersCount: number;
  maxUsersCount: number;
};

export const useTable = (onRenameUser: () => void): UsersTable => {
  const [selectedRows, setSelectedRows] = useState<Row[]>([]);
  const [resentInvite, setResentInvite] = useState(false);
  const { users, modals, currentUser, usersCount, maxUsersCount } = useSelector(
    (state: RootState) => state.users
  );
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const abortController = new AbortController();

    if (user?.orgId) {
      dispatch(getUsers(user?.orgId || '', abortController.signal));
    }

    return () => {
      abortController.abort();
      dispatch(clearUsers());
    };
  }, [dispatch, user?.orgId]);

  const handleSelect = (keys: React.Key[], rows: Row[]): void => {
    setSelectedRows(rows);
  };

  const invokeModal = useCallback((): void => {
    dispatch(invokeDeleteModal([currentUser as User]));
  }, [currentUser, dispatch]);

  const closeDeleteModal = () => {
    dispatch(closeDeleteUserModal());
  };

  const handleChooseUser = useCallback(
    (current: User): void => {
      dispatch(setCurrentUser(current));
    },
    [dispatch]
  );

  const handleRenameUser = useCallback((): void => {
    onRenameUser();
  }, [onRenameUser]);

  const toggleAssignRoleModal = useCallback((): void => {
    dispatch(invokeAssignRoleModal(currentUser as User));
  }, [currentUser, dispatch]);

  const handleResendInvite = useCallback(() => {
    setResentInvite(true);
    dispatch(
      resendInvite({
        orgId: currentUser?.orgId || '',
        userId: currentUser?.userId || '',
        inviteType:
          currentUser?.role !== 'owner' ? 'userInvite' : 'ownerInvite',
        requesterId: user?.userId || '',
      })
    );
    const timer = setTimeout(() => {
      dispatch(getUsers(user?.orgId || ''));
    }, 1000);
    return () => clearTimeout(timer);
  }, [currentUser, dispatch, user?.userId, user?.orgId]);

  const closeResentInviteModal = useCallback(() => {
    setResentInvite(false);
  }, [setResentInvite]);

  const conditionShowInvitation =
    moment().isAfter(currentUser?.inviteExpires) &&
    currentUser?.inviteStatus !== 'accepted';

  const permissionToRenameUser = () => {
    let permission = false;
    const selectedUser = currentUser;
    if (selectedUser?.userId === user?.userId) {
      permission = true;
    } else if (
      selectedUser?.inviteStatus === 'accepted' &&
      selectedUser.role !== 'superAdmin' &&
      selectedUser.role !== 'owner'
    ) {
      if (
        selectedUser.role === 'dealmaker' &&
        (user?.role === 'superAdmin' ||
          user?.role === 'owner' ||
          user?.role === 'admin')
      ) {
        permission = true;
      } else if (
        selectedUser.role === 'admin' &&
        (user?.role === 'superAdmin' || user?.role === 'owner')
      ) {
        permission = true;
      }
    }
    return permission;
  };

  const permissionToChangeUserRole =
    (user?.role === 'superAdmin' || user?.role === 'owner') &&
    currentUser?.userId !== user?.userId &&
    currentUser?.role !== 'superAdmin' &&
    currentUser?.role !== 'owner' &&
    currentUser?.inviteStatus === 'accepted';

  const permissionToDeleteUser =
    ((user?.role === 'superAdmin' || user?.role === 'owner') &&
      currentUser?.userId !== user?.userId &&
      currentUser?.role !== 'superAdmin' &&
      currentUser?.role !== 'owner') ||
    (user?.role === 'admin' && currentUser?.role === 'dealmaker');

  const options: JSX.Element[] = useMemo(
    () => [
      <PopupOption
        key="Rename user"
        className="options__option"
        text="Rename user"
        onChoose={permissionToRenameUser() ? handleRenameUser : noop}
        Icon={PencilPopupIcon}
        disabled={!permissionToRenameUser()}
      />,
      ...(conditionShowInvitation
        ? [
            <PopupOption
              key="Resend invitation"
              className="options__option"
              text="Resend invitation"
              onChoose={handleResendInvite}
              Icon={ResendInvitePopupIcon}
            />,
          ]
        : []),
      <PopupOption
        key="Assign a role"
        className="options__option"
        disabled={!permissionToChangeUserRole}
        text="Assign a role"
        onChoose={permissionToChangeUserRole ? toggleAssignRoleModal : noop}
        Icon={AssignRolePopupIcon}
      />,
      <PopupOption
        key="Delete user"
        className="options__option"
        disabled={!permissionToDeleteUser}
        text="Delete user"
        onChoose={permissionToDeleteUser ? invokeModal : noop}
        isRed
        Icon={DeleteUserPopupIcon}
      />,
    ],
    // eslint-disable-next-line
    [
      invokeModal,
      handleRenameUser,
      toggleAssignRoleModal,
      currentUser,
      user?.userId,
      handleResendInvite,
    ]
  );

  const cols = useMemo(() => getCols(options, handleChooseUser), [
    options,
    handleChooseUser,
  ]);

  const rowSelection = {
    onChange: handleSelect,
  };

  return {
    cols,
    users,
    rowSelection,
    selectedRows,
    invokeModal,
    deleteModalOpen: modals.deleteModalOpen,
    closeDeleteModal,
    assignRoleModalOpen: modals.assignRoleModalOpen,
    currentUser,
    orgId: user?.orgId || '',
    toggleAssignRoleModal,
    resentInvite,
    closeResentInviteModal,
    user,
    usersCount,
    maxUsersCount,
  };
};
