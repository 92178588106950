import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  Button,
  ButtonSize,
  ChevronDownIcon,
  Dropdown,
} from '../../../../components/SoundWave';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  getDetailedMeetingCountStats,
  getDetailedMeetingDurationStats,
  getDetailedTalkTimeRatioStats,
  getMeetingCountChartData,
  getMeetingDurationChartData,
  getTalkTimeRatioChartData,
} from '../../../../store/soundWaveTeamQ/soundWaveTeamQ.thunks';
import {
  getTeamQOverviewData,
  getTeamQActivityData,
  getTeamQAnalyticsData,
  initialRequestWasSent,
} from '../../../../store/teamQ/teamQ.actions';
import { getTeams } from '../../../../store/teams/teams.actions';
import {
  getDateRangeOptions,
  getLastNinetyDaysRangeOption,
} from '../../../../utils/getDateRangeDropdownOptions';
import { getDefaultSelectedTeamOption } from './utils/getDefaultSelectedTeamOption';

export const TeamQDateAndTeamSelectors: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const teams = useAppSelector((state) => state.teams.teams);

  const {
    initialReqestWasSent,
    activity,
    upperHand,
    estTendencies,
    sellingZone,
    threadDuration,
    threadLength,
    analyzed,
    simulated,
    analyticsUpperHand,
    analyticsSellZone,
    competence,
    empathy,
    responsiveness,
  } = useAppSelector((state) => state.teamQ);

  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const [selectedDateRange, setSelectedDateRange] = useState(
    getLastNinetyDaysRangeOption
  );

  const [selectedTeam, setSelectedTeam] = useState(
    getDefaultSelectedTeamOption
  );

  const [isTeamDropdownOpened, setIsTeamDropdownOpened] = useState(false);
  const [isDateDropdownOpened, setIsDateDropdownOpened] = useState(false);

  const dateRangeDropdownOptions = useMemo(
    () =>
      getDateRangeOptions().map(({ label, fromDate, toDate }) => ({
        label,
        className: 'teamq-date-and-team-selector__dropdown-option',
        disabled:
          fromDate === selectedDateRange.fromDate &&
          toDate === selectedDateRange.toDate,
        onClick: () => {
          setIsDateDropdownOpened(false);
          setSelectedDateRange(() => ({ label, fromDate, toDate }));
        },
      })),
    [selectedDateRange]
  );

  const teamDropdownOptions = useMemo(
    () =>
      [...teams, getDefaultSelectedTeamOption()].map(({ name, teamId }) => ({
        label: name,
        className: 'teamq-date-and-team-selector__dropdown-option',
        disabled: teamId === selectedTeam.teamId,
        onClick: () => {
          setIsTeamDropdownOpened(false);
          setSelectedTeam(() => ({ name, teamId }));
        },
      })),
    [selectedTeam, teams]
  );

  const attrs = {
    wrapper: {
      className: 'teamq-date-and-team-selector',
    },
    teamDropdown: {
      isOpened: isTeamDropdownOpened,
      closeOnScroll: true,
      options: teamDropdownOptions,
      onClose: () => {
        setIsTeamDropdownOpened(false);
      },
    },
    teamDropdownButton: {
      size: ButtonSize.S,
      secondary: true,
      className: `teamq-date-and-team-selector__button ${
        isTeamDropdownOpened
          ? 'teamq-date-and-team-selector__button--opened'
          : ''
      }`,
      onClick: () => {
        setIsTeamDropdownOpened(!isTeamDropdownOpened);
      },
    },
    teamDropdownButtonIcon: {
      className: 'teamq-date-and-team-selector__button-icon',
    },
    dateRangeDropdown: {
      isOpened: isDateDropdownOpened,
      closeOnScroll: true,
      options: dateRangeDropdownOptions,
      onClose: () => {
        setIsDateDropdownOpened(false);
      },
    },
    dateRangeDropdownButton: {
      size: ButtonSize.S,
      secondary: true,
      className: `teamq-date-and-team-selector__button ${
        isDateDropdownOpened
          ? 'teamq-date-and-team-selector__button--opened'
          : ''
      }`,
      onClick: () => {
        setIsDateDropdownOpened(!isDateDropdownOpened);
      },
    },
    dateRangeDropdownButtonIcon: {
      className: 'teamq-date-and-team-selector__button-icon',
    },
  };

  useEffect(() => {
    if (user && !teams.length) {
      dispatch(getTeams(user.orgId));
    }
  }, [user, teams.length, dispatch]);

  useEffect(() => {
    if (user) {
      const { teamId } = selectedTeam;
      const { fromDate, toDate } = selectedDateRange;

      const timePeriodInDays = DateTime.fromISO(toDate).diff(
        DateTime.fromISO(fromDate),
        'days'
      ).days;

      if (pathname.includes('overview')) {
        dispatch(
          getTeamQOverviewData(
            user.orgId,
            user.userId,
            fromDate,
            toDate,
            teamId || 'allUsers',
            timePeriodInDays,
            true
          )
        );

        dispatch(
          getMeetingCountChartData({
            teamId,
            fromDate,
            toDate,
          })
        );

        dispatch(
          getMeetingDurationChartData({
            teamId,
            fromDate,
            toDate,
          })
        );

        dispatch(
          getTalkTimeRatioChartData({
            teamId,
            fromDate,
            toDate,
          })
        );
      } else if (pathname.includes('activity')) {
        dispatch(
          getTeamQActivityData(
            user.orgId,
            user.userId,
            fromDate,
            toDate,
            teamId || 'allUsers',
            timePeriodInDays,
            true
          )
        );

        dispatch(
          getDetailedMeetingCountStats({
            teamId,
            fromDate,
            toDate,
          })
        );

        dispatch(
          getDetailedMeetingDurationStats({
            teamId,
            fromDate,
            toDate,
          })
        );
      } else if (pathname.includes('team-analytics/analytics')) {
        dispatch(
          getTeamQAnalyticsData(
            user.orgId,
            user.userId,
            fromDate,
            toDate,
            teamId || 'allUsers',
            timePeriodInDays,
            true
          )
        );

        dispatch(
          getDetailedTalkTimeRatioStats({
            teamId,
            fromDate,
            toDate,
          })
        );
      }

      setTimeout(() => {
        dispatch(initialRequestWasSent());
      }, 1000);
    }
  }, [selectedTeam, selectedDateRange, user]); // eslint-disable-line

  useEffect(() => {
    if (user && initialReqestWasSent) {
      const overviewNoDataCheck =
        !activity && !upperHand && !estTendencies && !sellingZone;

      const activityNoDataCheck =
        !threadDuration && !threadLength && !analyzed && !simulated;

      const analyticsNoDataCheck =
        !analyticsUpperHand &&
        !analyticsSellZone &&
        !competence &&
        !empathy &&
        !responsiveness;

      const { teamId } = selectedTeam;
      const { fromDate, toDate } = selectedDateRange;

      const timePeriodInDays = DateTime.fromISO(toDate).diff(
        DateTime.fromISO(fromDate),
        'days'
      ).days;

      if (pathname.includes('overview') && overviewNoDataCheck) {
        dispatch(
          getTeamQOverviewData(
            user.orgId,
            user.userId,
            fromDate,
            toDate,
            teamId || 'allUsers',
            timePeriodInDays
          )
        );

        dispatch(
          getMeetingCountChartData({
            teamId,
            fromDate,
            toDate,
          })
        );

        dispatch(
          getMeetingDurationChartData({
            teamId,
            fromDate,
            toDate,
          })
        );

        dispatch(
          getTalkTimeRatioChartData({
            teamId,
            fromDate,
            toDate,
          })
        );
      } else if (pathname.includes('activity') && activityNoDataCheck) {
        dispatch(
          getTeamQActivityData(
            user.orgId,
            user.userId,
            fromDate,
            toDate,
            teamId || 'allUsers',
            timePeriodInDays
          )
        );

        dispatch(
          getDetailedMeetingCountStats({
            teamId,
            fromDate,
            toDate,
          })
        );

        dispatch(
          getDetailedMeetingDurationStats({
            teamId,
            fromDate,
            toDate,
          })
        );
      } else if (
        pathname.includes('team-analytics/analytics') &&
        analyticsNoDataCheck
      ) {
        dispatch(
          getTeamQAnalyticsData(
            user.orgId,
            user.userId,
            fromDate,
            toDate,
            teamId || 'allUsers',
            timePeriodInDays
          )
        );

        dispatch(
          getDetailedTalkTimeRatioStats({
            teamId,
            fromDate,
            toDate,
          })
        );
      }
    } // eslint-disable-next-line
  }, [pathname]);

  return (
    <div {...attrs.wrapper}>
      <Dropdown {...attrs.teamDropdown}>
        <Button {...attrs.teamDropdownButton}>
          <span>{selectedTeam.name}</span>
          <ChevronDownIcon {...attrs.teamDropdownButtonIcon} />
        </Button>
      </Dropdown>
      <Dropdown {...attrs.dateRangeDropdown}>
        <Button {...attrs.dateRangeDropdownButton}>
          <span>{selectedDateRange.label}</span>
          <ChevronDownIcon {...attrs.dateRangeDropdownButtonIcon} />
        </Button>
      </Dropdown>
    </div>
  );
};
