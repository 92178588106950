import React from 'react';

export enum ButtonSize {
  XS = 'xs',
  S = 's',
  M = 'm',
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<
    HTMLButtonElement | HTMLLabelElement | HTMLAnchorElement
  > {
  as?: 'button' | 'label' | 'a';
  secondary?: boolean;
  danger?: boolean;
  transparent?: boolean;
  error?: boolean;
  success?: boolean;
  size?: ButtonSize;
}

export const Button: React.FC<ButtonProps> = ({
  as = 'button',
  children,
  className,
  secondary = false,
  danger = false,
  transparent = false,
  error = false,
  success = false,
  size = ButtonSize.M,
  type = 'button',
  ...rest
}) => {
  const Component = as;

  const attrs = {
    button: {
      className: `sound-wave-button${
        secondary ? ' sound-wave-button--secondary' : ''
      }${danger ? ' sound-wave-button--danger' : ''}${
        size ? ` sound-wave-button--${size}` : ''
      }${transparent ? ' sound-wave-button--transparent' : ''}${
        className ? ` ${className}` : ''
      }${error ? ' sound-wave-button--error' : ''}${
        success ? ' sound-wave-button--success' : ''
      }`,
      ...(as === 'button' && { type }),
      ...rest,
    },
  };

  return <Component {...attrs.button}>{children}</Component>;
};
