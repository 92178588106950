import React, { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Modal } from '../../../../../Modal/Modal';
import { MinusIcon } from './icons/MinusIcon';
import { PlusIcon } from './icons/PlusIcon';
import {
  useImageCropper,
  Area,
} from '../../../../../../../hooks/useImageCropper';
import { ImagePlaceholderIcon } from '../../../../../../../Icons/ImagePlaceholderIcon';
import { OptionsIcon } from './icons/OptionsIcon';
import { UploadNewIcon } from './icons/UploadNewIcon';
import { RemoveIcon } from './icons/RemoveIcon';
import { Button, ButtonSize } from '../../../../../Button/Button';

export interface ImageCropperProps {
  id?: string;
  image?: string;
  isOpened?: boolean;
  rounded?: boolean;
  placeholderIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  title?: string;
  onCancel?: () => void;
  onSave?: (image: string) => void;
  onRemove?: () => void;
}

export const ImageCropper: React.FC<ImageCropperProps> = ({
  id,
  image,
  placeholderIcon,
  isOpened = false,
  rounded = false,
  title,
  onCancel,
  onSave,
  onRemove,
}) => {
  const {
    getCroppedImg,
    croppedAreaPixels,
    crop,
    zoom,
    onCropChange,
    onZoomChange,
    onCropComplete,
  } = useImageCropper();

  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const zoomPercentageString = `${Math.round((zoom - 1) * 100)}%`;

  const PlaceholderIconComponent = placeholderIcon || ImagePlaceholderIcon;

  const attrs = {
    modal: {
      isOpened,
      closeOnClickOutside: true,
      width: 460,
      onClose: () => {
        onCancel?.();
      },
      headerProps: {
        title: image ? 'Edit Image' : title || 'Upload Image',
      },
      footerProps: {
        cancelButtonText: 'Discard',
        confirmButtonText: 'Save',
        onConfirm: async () => {
          if (image) {
            const croppedImage = await getCroppedImg(
              image,
              croppedAreaPixels as Area
            );
            onSave?.(croppedImage);
          } else {
            onCancel?.();
          }
        },
      },
    },
    container: {
      className: 'sound-wave-image-cropper',
    },
    cropperWrapper: {
      className: 'sound-wave-image-cropper__cropper-wrapper',
    },
    cropper: {
      image,
      crop,
      zoom,
      onCropChange,
      onZoomChange,
      onCropComplete,
      cropShape: rounded ? ('round' as const) : ('rect' as const),
      zoomSpeed: 0.1,
      aspect: 1,
      showGrid: false,
      classes: {
        containerClassName: 'sound-wave-image-cropper__cropper-container',
        cropAreaClassName: `sound-wave-image-cropper__cropper-area${
          !rounded ? ' sound-wave-image-cropper__cropper-area--rect' : ''
        }`,
      },
      cropSize: {
        width: 232,
        height: 232,
      },
    },
    content: {
      className: 'sound-wave-image-cropper__content',
    },
    sliderWrapper: {
      className: 'sound-wave-image-cropper__slider-wrapper',
    },
    slider: {
      type: 'range',
      min: 1,
      max: 3,
      step: 0.005,
      value: zoom,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        onZoomChange(parseFloat(e.target.value));
      },
      className: 'sound-wave-image-cropper__slider',
    },
    zoomOutButton: {
      type: 'button' as const,
      className: 'sound-wave-image-cropper__button',
      onClick: () => {
        onZoomChange(Math.max(zoom - 0.1, 1));
      },
    },
    zoomInButton: {
      type: 'button' as const,
      className: 'sound-wave-image-cropper__button',
      onClick: () => {
        onZoomChange(Math.min(zoom + 0.1, 3));
      },
    },
    buttonIcon: {
      className: 'sound-wave-image-cropper__button-icon',
    },
    emptyWrapper: {
      className: 'sound-wave-image-cropper__empty-wrapper',
    },
    imagePlaceholder: {
      className: `sound-wave-image-cropper__image-placeholder${
        !rounded ? ' sound-wave-image-cropper__image-placeholder--rect' : ''
      }`,
    },
    imagePlaceholderIcon: {
      className: 'sound-wave-image-cropper__image-placeholder-icon',
    },
    label: {
      size: ButtonSize.S,
      as: 'label' as const,
      htmlFor: id,
      secondary: true,
    },
    dropdownWrapper: {
      className: 'sound-wave-image-cropper__dropdown-wrapper',
    },
    dropdownButton: {
      type: 'button' as const,
      className: 'sound-wave-image-cropper__dropdown-button',
      onClick: () => {
        setIsDropdownOpened(!isDropdownOpened);
      },
    },
    dropdownButtonIcon: {
      className: 'sound-wave-image-cropper__dropdown-button-icon',
    },
    dropdown: {
      className: `sound-wave-image-cropper__dropdown${
        isDropdownOpened ? ' sound-wave-image-cropper__dropdown--opened' : ''
      }`,
    },
    uploadNewOption: {
      htmlFor: id,
      className: 'sound-wave-image-cropper__dropdown-option',
      onClick: () => {
        setIsDropdownOpened(false);
      },
    },
    removeOption: {
      type: 'button' as const,
      className: 'sound-wave-image-cropper__dropdown-option',
      onClick: () => {
        setIsDropdownOpened(false);
        onRemove?.();
      },
    },
    dropdownOptionIcon: {
      className: 'sound-wave-image-cropper__dropdown-option-icon',
    },
  };

  useEffect(() => {
    if (image) {
      onZoomChange(2);
    }
  }, [image, onZoomChange]);

  return (
    <Modal {...attrs.modal}>
      {image ? (
        <div {...attrs.container}>
          <div {...attrs.cropperWrapper}>
            <Cropper {...attrs.cropper} />
            <div {...attrs.dropdownWrapper}>
              <button {...attrs.dropdownButton}>
                <OptionsIcon {...attrs.dropdownButtonIcon} />
              </button>
              <div {...attrs.dropdown}>
                <label {...attrs.uploadNewOption}>
                  <span>Upload new</span>
                  <UploadNewIcon {...attrs.dropdownOptionIcon} />
                </label>
                <button {...attrs.removeOption}>
                  <span>Delete image</span>
                  <RemoveIcon {...attrs.dropdownOptionIcon} />
                </button>
              </div>
            </div>
          </div>
          <div {...attrs.content}>
            <span>{zoomPercentageString}</span>
            <div {...attrs.sliderWrapper}>
              <button {...attrs.zoomOutButton}>
                <MinusIcon {...attrs.buttonIcon} />
              </button>
              <input {...attrs.slider} />
              <button {...attrs.zoomInButton}>
                <PlusIcon {...attrs.buttonIcon} />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div {...attrs.emptyWrapper}>
          <div {...attrs.imagePlaceholder}>
            <PlaceholderIconComponent {...attrs.imagePlaceholderIcon} />
          </div>
          <Button {...attrs.label}>
            <span>Upload Image</span>
          </Button>
        </div>
      )}
    </Modal>
  );
};
