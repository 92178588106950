import { Skeleton } from 'antd';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { openAddNoteModal } from '../../../../../../store/relationshipFlows/relationshipFlows.slice';
import { PlusIcon } from '../../icons/PlusIcon';
import { Scrollbar } from '../../../../../../components';
import { NoteItem } from './components/NoteItem/NoteItem';
import { Button, ButtonSize } from '../../../../../../components/SoundWave';

export const Notes: React.FC = () => {
  const dispatch = useAppDispatch();

  const account = useAppSelector((state) => state.relationshipDetails.account);
  const notes = useAppSelector((state) => state.relationshipDetails.notes);

  const isAccountLoading = useAppSelector(
    (state) => state.relationshipDetails.isLoading.account
  );

  const isNotesLoading = useAppSelector(
    (state) => state.relationshipDetails.isLoading.notes
  );

  const notesCounterText =
    notes.length === 1 ? '1 Note' : `${notes.length} Notes`;

  const attrs = {
    container: {
      className: 'rs-details-notes',
    },
    header: {
      className: 'rs-details-notes__header',
    },
    headerTextWrapper: {
      className: 'rs-details-notes__header-text-wrapper',
    },
    headerTitle: {
      className: 'rs-details-notes__header-title',
    },
    headerSubtitle: {
      className: 'rs-details-notes__header-subtitle',
    },
    button: {
      size: ButtonSize.XS,
      secondary: true,
      disabled: isNotesLoading || isAccountLoading || !account,
      onClick: () => {
        if (account) {
          dispatch(
            openAddNoteModal({
              accountId: account.accountId,
            })
          );
        }
      },
    },
    content: {
      className: 'rs-details-notes__content',
    },
    emptyContent: {
      className: 'rs-details-notes__empty-content',
    },
    srollbar: {
      className: 'rs-details-notes__scrollbar',
    },
    headerSubtitleSkeleton: {
      active: true,
      paragraph: false,
      style: {
        width: 46,
      },
      title: {
        style: {
          width: 46,
          height: 19.5,
          margin: 0,
          borderRadius: 8,
        },
      },
    },
    headerButtonSkeleton: {
      active: true,
      paragraph: false,
      style: {
        width: '94px',
      },
      title: {
        style: {
          width: '100%',
          height: 34,
          margin: 0,
          borderRadius: 8,
        },
      },
    },
    noteItemSkeleton: {
      active: true,
      paragraph: false,
      style: {
        width: '100%',
        padding: 16,
      },
      title: {
        style: {
          width: '100%',
          height: 56,
          margin: 0,
          borderRadius: 12,
        },
      },
    },
  };

  const renderNotes = () => {
    if (isNotesLoading) {
      return <Skeleton {...attrs.noteItemSkeleton} />;
    }

    return notes.map((note) => {
      const props = {
        key: note.noteId,
        ...note,
      };

      return <NoteItem {...props} />;
    });
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.header}>
        <div {...attrs.headerTextWrapper}>
          <span {...attrs.headerTitle}>Notes</span>
          {isNotesLoading ? (
            <Skeleton {...attrs.headerSubtitleSkeleton} />
          ) : (
            <span {...attrs.headerSubtitle}>{notesCounterText}</span>
          )}
        </div>
        {isNotesLoading ? (
          <Skeleton {...attrs.headerButtonSkeleton} />
        ) : (
          <Button {...attrs.button}>
            <PlusIcon />
            <span>Add Note</span>
          </Button>
        )}
      </div>
      <div {...attrs.content}>
        {!notes.length && !isNotesLoading ? (
          <div {...attrs.emptyContent}>You don’t have any notes yet</div>
        ) : (
          <Scrollbar {...attrs.srollbar}>{renderNotes()}</Scrollbar>
        )}
      </div>
    </div>
  );
};
