import React, { useEffect, useRef } from 'react';
import { DateTime } from 'luxon';
import { Skeleton } from 'antd';
import { RelationshipMemberSide } from '../../../../../../types/relationshipMember.types';
import { EnvelopeIcon } from './icons/EnvelopeIcon';
import {
  Avatar,
  AvatarSize,
} from '../../../../../../components/SoundWave/components/Avatar/Avatar';

export interface EmailListItemProps {
  content?: string;
  isActive?: boolean;
  index?: number;
  isLoading?: boolean;
  sender?: {
    email: string;
    metadata?: {
      name: string | null;
      avatarSrc: string | null;
    };
  };
  sentAt?: string;
  side?: RelationshipMemberSide;
  total?: number;
  messageId?: string;
  scrollableNodeRef?: React.RefObject<HTMLElement>;
  onSelect?: (messageId: string) => void;
}

export const EmailListItem: React.FC<EmailListItemProps> = ({
  content,
  isActive = false,
  index = 0,
  isLoading = false,
  sender,
  sentAt,
  side = RelationshipMemberSide.UNASSIGNED,
  total = 0,
  messageId,
  scrollableNodeRef,
  onSelect,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const senderName = sender?.metadata?.name || '';
  const senderEmail = sender?.email || '';
  const counterText = `${index + 1}/${total}`;
  const sentAtDateString = sentAt
    ? DateTime.fromISO(sentAt).toFormat('MMM dd, yyyy, hh:mm a')
    : '';

  const attrs = {
    container: {
      ref: containerRef,
      className: `email-list-item email-list-item--${side}${
        isActive ? ' email-list-item--active' : ''
      }${isLoading ? ' email-list-item--loading' : ''}`,
      onClick: () => {
        if (!isActive && !isLoading && messageId) {
          onSelect?.(messageId);
        }
      },
    },
    header: {
      className: 'email-list-item__header',
    },
    headerContent: {
      className: 'email-list-item__header-content',
    },
    iconsWrapper: {
      className: 'email-list-item__icons-wrapper',
    },
    envelopeIconWrapper: {
      className: 'email-list-item__envelope-icon-wrapper',
    },
    envelopeIcon: {
      className: 'email-list-item__envelope-icon',
    },
    senderAvatar: {
      side,
      size: AvatarSize.S,
      src: sender?.metadata?.avatarSrc || '',
      name: senderName || senderEmail,
      className: sender?.metadata?.avatarSrc ? '' : 'email-list-item__avatar',
    },
    senderInfoWrapper: {
      className: 'email-list-item__sender-info-wrapper',
    },
    senderName: {
      className: 'email-list-item__sender-name',
    },
    text: {
      className: `email-list-item__text${
        isActive ? ' email-list-item__text--darker' : ''
      }`,
    },
    textBold: {
      className:
        'email-list-item__text email-list-item__text--darker email-list-item__text--bold',
    },
    indicator: {
      className: 'email-list-item__indicator',
    },
    indicatorOuterCircle: {
      className: 'email-list-item__indicator-outer-circle',
    },
    indicatorInnerCircle: {
      className: 'email-list-item__indicator-inner-circle',
    },
    content: {
      className: 'email-list-item__content',
    },
    avatarSkeleton: {
      active: true,
      paragraph: false,
      title: false,
      avatar: {
        shape: 'circle' as const,
        size: 28,
      },
      style: {
        width: 'auto',
      },
    },
    senderNameSkeleton: {
      active: true,
      paragraph: false,
      style: {
        width: 100,
      },
      title: {
        style: {
          width: 100,
          height: 20,
          margin: 0,
          borderRadius: 4,
        },
      },
    },
    textSkeleton: {
      active: true,
      paragraph: false,
      style: {
        width: 120,
      },
      title: {
        style: {
          width: 120,
          height: 16,
          margin: 0,
          borderRadius: 4,
        },
      },
    },
    contentSkeleton: {
      active: true,
      title: false,
      paragraph: {
        rows: 10,
        width: '100%',
        style: {
          margin: 0,
        },
      },
    },
  };

  useEffect(() => {
    if (isActive && containerRef.current && scrollableNodeRef?.current) {
      const containerClientHeight = containerRef.current.clientHeight;
      const scrollableNodeClientHeight = scrollableNodeRef.current.clientHeight;

      const offsetTop =
        scrollableNodeClientHeight / 2 - containerClientHeight / 2;

      scrollableNodeRef.current.scroll({
        top:
          containerClientHeight >= scrollableNodeClientHeight
            ? containerRef.current.offsetTop
            : containerRef.current.offsetTop - offsetTop,
        behavior: 'smooth',
      });
    }
  }, [isActive, scrollableNodeRef]);

  return (
    <div {...attrs.container}>
      <div {...attrs.header}>
        <div {...attrs.headerContent}>
          <div {...attrs.iconsWrapper}>
            {isLoading ? (
              <>
                <Skeleton {...attrs.avatarSkeleton} />
                <Skeleton {...attrs.avatarSkeleton} />
              </>
            ) : (
              <>
                <div {...attrs.envelopeIconWrapper}>
                  <EnvelopeIcon {...attrs.envelopeIcon} />
                </div>
                <Avatar {...attrs.senderAvatar} />
              </>
            )}
          </div>
          <div {...attrs.senderInfoWrapper}>
            {isLoading ? (
              <Skeleton {...attrs.senderNameSkeleton} />
            ) : (
              <span {...attrs.senderName}>{senderName || senderEmail}</span>
            )}
            {isLoading ? (
              <Skeleton {...attrs.textSkeleton} />
            ) : (
              <span {...attrs.textBold}>{senderName ? senderEmail : null}</span>
            )}
          </div>
        </div>
        <div {...attrs.headerContent}>
          {isLoading ? (
            <Skeleton {...attrs.textSkeleton} />
          ) : (
            <>
              <span {...attrs.text}>{sentAtDateString}</span>
              <span {...attrs.text}>|</span>
              <span {...attrs.text}>{counterText}</span>
              <div {...attrs.indicator}>
                <span {...attrs.indicatorOuterCircle} />
                <span {...attrs.indicatorInnerCircle} />
              </div>
            </>
          )}
        </div>
      </div>
      <div {...attrs.content}>
        {isLoading ? <Skeleton {...attrs.contentSkeleton} /> : content}
      </div>
    </div>
  );
};
