import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getUserData } from '../../store/auth/auth.actions';
import { MyProfile } from './components/myProfile/MyProfile';
import { MyOrgAndTeams } from './components/myOrgAndTeams/MyOrgAndTeams';
import { MyActivity } from './components/myActivity/MyActivity';
import {
  getTeams,
  getTeamsForDealmaker,
} from '../../store/teams/teams.actions';
import { useTrackPageView } from '../../hooks';

export const Account: React.FC = (): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(getUserData(user.orgId, user.userId));

      if (
        user.role === 'superAdmin' ||
        user.role === 'owner' ||
        user.role === 'admin'
      ) {
        dispatch(getTeams(user.orgId));
      } else if (user.role === 'dealmaker') {
        dispatch(getTeamsForDealmaker(user.orgId, user.userId));
      }
    }
  }, []); // eslint-disable-line

  useTrackPageView();

  const attrs = {
    wrapper: {
      className: 'account',
      id: 'account',
    },
  };

  return (
    <div {...attrs.wrapper}>
      <MyProfile />
      <MyOrgAndTeams />
      <MyActivity />
    </div>
  );
};
