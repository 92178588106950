import React from 'react';
import { MeetingRecordsList } from './components/MeetingRecordsList/MeetingRecordsList';
import { AddBotModal } from '../MyRelationships/components/addBotModal/AddBotModal';
import { InviteAssistantButton } from '../../components/SoundWave';
import { useTrackPageView } from '../../hooks';

export const MeetingRecords: React.FC = () => {
  const attrs = {
    container: {
      className: 'meeting-records',
    },
    header: {
      className: 'meeting-records__header',
    },
    title: {
      className: 'meeting-records__title',
    },
  };

  useTrackPageView();

  return (
    <div {...attrs.container}>
      <div {...attrs.header}>
        <span {...attrs.title}>My Meetings</span>
        <InviteAssistantButton />
      </div>
      <MeetingRecordsList />
      <AddBotModal />
    </div>
  );
};
