export const MODE: string = process.env.REACT_APP_MODE || 'local';

let apiLink = '';

switch (process.env.REACT_APP_MODE) {
  case 'prod':
    apiLink = 'https://beta-api.substrata.me';
    break;
  case 'stage':
    apiLink = 'https://staging.beta-api.substrata.me';
    break;
  default:
    apiLink = 'http://localhost:8002';
    break;
}

export const API_LINK: string = process.env.REACT_APP_API_LINK || apiLink;

let signupLink = '';

switch (process.env.REACT_APP_MODE) {
  case 'prod':
    signupLink = 'https://signup.substrata.me';
    break;
  case 'stage':
    signupLink = 'https://staging.signup.substrata.me';
    break;
  default:
    signupLink = 'http://localhost:3002';
    break;
}

export const SIGNUP_LINK: string =
  process.env.REACT_APP_SIGNUP_URL || signupLink;

let accountUrl = '';

switch (MODE) {
  case 'prod':
    accountUrl = 'https://account.substrata.me';
    break;
  case 'stage':
    accountUrl = 'https://staging.account.substrata.me';
    break;
  default:
    accountUrl = 'http://localhost:3003';
    break;
}

export const ACCOUNT_URL: string =
  process.env.REACT_APP_ACCOUNT_URL || accountUrl;

let mixPanelToken = '';

switch (MODE) {
  case 'prod':
    mixPanelToken = '5c1b399da91785f377e01401f7660db7';
    break;
  case 'stage':
    mixPanelToken = 'a10f3438f40978dcfdf3e97dbda8c55a';
    break;
  default:
    mixPanelToken = '512032c4a184cd53742c05bd87f99e8e';
    break;
}

export const MIX_PANEL_TOKEN: string =
  process.env.REACT_APP_MIXPANEL_TOKEN || mixPanelToken;

let intercomAppId = '';

switch (MODE) {
  case 'prod':
    intercomAppId = 'teb3iu06';
    break;
  case 'stage':
    intercomAppId = 'fxrfvhkr';
    break;
  default:
    intercomAppId = 'fxrfvhkr';
    break;
}

export const INTERCOM_APP_ID: string =
  process.env.REACT_APP_INTERCOM_APP_ID || intercomAppId;
