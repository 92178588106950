import { Contact } from './contact.types';

export enum EmailDominantParticipant {
  LEFT = 'left',
  RIGHT = 'right',
  UNRESOLVED = 'unresolved',
}

export enum EmailCommunicationType {
  MIX = 'mix',
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export enum EmailParticipantType {
  TO = 'to',
  CC = 'cc',
  BCC = 'bcc',
}

export enum EmailRelativeMetricUnit {
  HOURS = 'hrs',
  WORDS = 'words',
}

export interface EmailSentiment {
  mag: number;
}

export interface EmailParticipantMetadata {
  name: string | null;
  avatarSrc: string | null;
  linkedin: string | null;
}

export interface EmailParticipant {
  email: string;
  metadata: EmailParticipantMetadata;
  isSender: boolean;
  isUser: boolean;
  isUserDomain: boolean;
  numberEmailsInBranch: number;
}

export type EmailSender = Pick<EmailParticipant, 'email' | 'metadata'>;

export interface EmailRecipient extends EmailSender {
  type: EmailParticipantType;
  index: number;
}

export interface EmailAssessmentInsight {
  text: string;
  color?: string;
}

export interface EmailRelativeMetric<T extends EmailRelativeMetricUnit> {
  currentEmailValue: number | null;
  insightText: string | null;
  previousEmailValue: number | null;
  color: 'red' | 'green' | null;
  units: T;
}

export interface Email {
  index: number | null;
  messageId: string | null;
  participants: {
    leftParticipant: EmailParticipant;
    rightParticipant: EmailParticipant;
  };
  subject: string | null;
  sentAt: string;
  sender: EmailSender;
  recipients: EmailRecipient[];
  scoreInBranch: number;
  platformMessageId: string;
  sentiment: EmailSentiment | null;
  topics: string[];
  dominantParticipant: EmailDominantParticipant | null;
  text: string;
  communicationType?: EmailCommunicationType;
  platformThreadId: string;
  platformDraftId: string;
  assessmentInsights: {
    assessmentInsights: EmailAssessmentInsight[];
    lastEmailRelativeMetrics: {
      relativeReplyTime: EmailRelativeMetric<EmailRelativeMetricUnit.HOURS>;
      relativeWordCount: EmailRelativeMetric<EmailRelativeMetricUnit.WORDS>;
    };
  };
}

export interface EmailWithContactsInfo extends Email {
  sender: EmailSender & { contact: Contact | null };
  participants: {
    leftParticipant: EmailParticipant & { contact: Contact | null };
    rightParticipant: EmailParticipant & { contact: Contact | null };
  };
  recipients: Array<EmailRecipient & { contact: Contact | null }>;
}

export interface EmailBranchNextMove {
  type: string | null;
  value: string | null;
}

export interface EmailBranch {
  branchId: string;
  nextMove: EmailBranchNextMove;
  subject: string | null;
  emails: Email[];
  avgOtherSideReplyTimeHours: number | null;
  avgUserSideReplyTimeHours: number | null;
  avgWordCountOtherSide: number | null;
  avgWordCountUserSide: number | null;
  userSidePerceivedCompetence: number | null;
  branchDurationWeeks: number | null;
  mainBranchParticipants: EmailParticipant[];
  accessEventsCount?: number | null;
}

export interface EmailBranchWithContactsInfo extends EmailBranch {
  emails: EmailWithContactsInfo[];
}

export interface EmailAnalysis {
  branch: EmailBranch;
  isThreadInternal: boolean;
  threadHasMessagesInTrash: boolean;
}
