import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setAccountTeamsSectionActive } from '../../store/auth/auth.actions';
import { RootState } from '../../store/store';

export const TeamsForRedirect: React.FC = () => {
  const { user, isAccountTeamTabActive } = useSelector(
    (state: RootState) => state.auth
  );

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      if (!isAccountTeamTabActive) {
        dispatch(setAccountTeamsSectionActive(true));
      }

      history.push(`/orgs/${user.orgId}/users/${user.userId}`);
    }
  }, [user, history, isAccountTeamTabActive, dispatch]);

  return null;
};
