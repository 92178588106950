import { Dispatch } from 'redux';
import {
  ClearWebhookDataAction,
  GetOrganizationAction,
  GetWebhookEventsAction,
  GetWebhookSettingsAction,
  GetWebhookSettingsKeyAction,
  OrgsActions,
  ResetWebhookStatusAction,
  SetWebhookKeyLoadingAction,
  SubscriptionBodyToSend,
  ToggleWebhookModalAction,
  UpdateOrgValues,
  UpdateWebhookEventsAction,
  UpdateWebhookFailAction,
  UpdateWebhookLoadingAction,
  UpdateWebhookSettingsAction,
  UpdateWebhookSuccessAction,
  WebhookEventTypes,
  WebhookSettingsToUpdate,
} from './org.types';
import axios from '../../axios';

export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const SET_LOADING = 'SET_LOADING';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_ORG_NAME_SUCCESS = 'UPDATE_ORG_NAME_SUCCESS';
export const UPDATE_ORG_NAME_ERROR = 'UPDATE_ORG_NAME_ERROR';
export const CLEAR_UPDATE_ORG_STATUS = 'CLEAR_UPDATE_ORG_STATUS';
export const TOGGLE_ORG_APP_FEATURE = 'TOGGLE_ORG_APP_FEATURE';

export const GET_WEBHOOK_EVENTS = 'GET_WEBHOOK_EVENTS';
export const UPDATE_WEBHOOK_EVENTS = 'UPDATE_WEBHOOK_EVENTS';

export const GET_WEBHOOK_SETTINGS = 'GET_WEBHOOK_SETTINGS';
export const GET_WEBHOOK_SETTINGS_KEY = 'GET_WEBHOOK_SETTINGS_KEY';
export const SET_WEBHOOK_KEY_LOADING = 'SET_WEBHOOK_KEY_LOADING';

export const UPDATE_WEBHOOK_SETTINGS = 'UPDATE_WEBHOOK_SETTINGS';
export const UPDATE_WEBHOOK_LOADING = 'UPDATE_WEBHOOK_LOADING';
export const UPDATE_WEBHOOK_SUCCESS = 'UPDATE_WEBHOOK_SUCCESS';
export const UPDATE_WEBHOOK_FAILED = 'UPDATE_WEBHOOK_FAILED';

export const TOGGLE_WEBHOOK_STATUS_MODAL = 'TOGGLE_WEBHOOK_STATUS_MODAL';

export const RESET_WEBHOOK_STATUS = 'RESET_WEBHOOK_STATUS';
export const CLEAR_WEBHOOK_DATA = 'CLEAR_WEBHOOK_DATA';

export const getOrganization = (orgId: string) => async (
  dispatch: Dispatch<GetOrganizationAction>
): Promise<void> => {
  const getOrganizationResponse = await axios.get(
    `/services/orgs/${orgId}?extended=true`
  );
  dispatch({
    type: GET_ORGANIZATION,
    payload: getOrganizationResponse.data.payload,
  });
};

export const updateOrganization = (
  values: Partial<UpdateOrgValues>,
  orgId: string,
  isCurrentOrg: boolean
) => async (dispatch: Dispatch<OrgsActions>): Promise<void> => {
  try {
    dispatch({
      type: CLEAR_UPDATE_ORG_STATUS,
    });

    dispatch({
      type: SET_LOADING,
      payload: true,
    });

    const valuesToUpdate = values.newAvatar
      ? {
          ...values,
          newAvatar: values.newAvatar
            ? {
                contentType: 'image/png',
                b64content: values.newAvatar.split(',')[1],
              }
            : null,
        }
      : values;

    const updatedOrg = await axios.patch(
      `/services/orgs/${orgId}`,
      valuesToUpdate
    );

    dispatch({
      type: UPDATE_ORGANIZATION,
      payload: { org: updatedOrg.data.payload, isCurrentOrg },
    });

    dispatch({
      type: UPDATE_ORG_NAME_SUCCESS,
      payload: true,
    });

    dispatch({
      type: SET_LOADING,
      payload: false,
    });

    dispatch({
      type: CLEAR_UPDATE_ORG_STATUS,
    });

    // eslint-disable-next-line
  } catch (e: any) {
    if (e?.response?.data?.code === 409) {
      dispatch({
        type: UPDATE_ORG_NAME_ERROR,
        payload: true,
      });
    }

    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};

export const toggleOrgAppFeature = (
  active: boolean,
  appName: string,
  orgId: string
) => async (dispatch: Dispatch): Promise<void> => {
  try {
    await axios.patch(`/services/orgs/${orgId}/apps/${appName}`, {
      active,
    });

    dispatch({
      type: TOGGLE_ORG_APP_FEATURE,
      payload: { active, appName },
    });
  } catch (e) {
    // handle ERROR
  }
};

export const getWebhookSettings = (orgId: string) => async (
  dispatch: Dispatch<GetWebhookSettingsAction>
): Promise<void> => {
  try {
    const getSettings = await axios.get(
      `services/orgs/${orgId}/webhook-settings`
    );

    dispatch({
      type: GET_WEBHOOK_SETTINGS,
      payload: getSettings.data.payload,
    });
  } catch (e) {
    // handle ERROR
  }
};

export const getWebhookSettingsKey = (
  orgId: string,
  oldKey: string | null,
  refetch?: boolean
) => async (
  dispatch: Dispatch<GetWebhookSettingsKeyAction | SetWebhookKeyLoadingAction>
): Promise<void> => {
  const refetchPath = refetch ? '/refresh' : '';

  try {
    dispatch({
      type: SET_WEBHOOK_KEY_LOADING,
      payload: true,
    });

    const getSettingsKey = await axios.get(
      `services/orgs/${orgId}/webhook-settings/key${refetchPath}`
    );

    dispatch({
      type: GET_WEBHOOK_SETTINGS_KEY,
      payload: { ...getSettingsKey.data.payload, oldKey: oldKey || '' },
    });

    dispatch({
      type: SET_WEBHOOK_KEY_LOADING,
      payload: false,
    });
  } catch (e) {
    dispatch({
      type: SET_WEBHOOK_KEY_LOADING,
      payload: false,
    });
  }
};

export const updateWebhookSettings = (
  orgId: string,
  settings: WebhookSettingsToUpdate
) => async (
  dispatch: Dispatch<
    | UpdateWebhookSettingsAction
    | UpdateWebhookLoadingAction
    | UpdateWebhookSuccessAction
    | UpdateWebhookFailAction
    | ResetWebhookStatusAction
  >
): Promise<void> => {
  try {
    dispatch({
      type: RESET_WEBHOOK_STATUS,
    });

    dispatch({
      type: UPDATE_WEBHOOK_LOADING,
      payload: true,
    });
    const body = settings;

    const updateSettings = await axios.post(
      `services/orgs/${orgId}/webhook-settings`,
      body
    );

    dispatch({
      type: UPDATE_WEBHOOK_SETTINGS,
      payload: updateSettings.data.payload,
    });

    dispatch({
      type: UPDATE_WEBHOOK_SUCCESS,
    });

    dispatch({
      type: UPDATE_WEBHOOK_LOADING,
      payload: false,
    });
  } catch (e: any) {
    // handle ERROR

    if (e?.response?.status === 424) {
      dispatch({
        type: UPDATE_WEBHOOK_FAILED,
        payload: e?.response?.data?.error[0] || '',
      });
    } else {
      dispatch({
        type: UPDATE_WEBHOOK_FAILED,
      });
    }

    dispatch({
      type: UPDATE_WEBHOOK_LOADING,
      payload: false,
    });
  }
};

export const getWebhookEvents = (orgId: string, userId: string) => async (
  dispatch: Dispatch<GetWebhookEventsAction>
): Promise<void> => {
  try {
    const getEvents = await axios.get(
      `services/orgs/${orgId}/users/${userId}/webhooks`
    );

    dispatch({
      type: GET_WEBHOOK_EVENTS,
      payload: getEvents.data.payload,
    });
  } catch (e) {
    dispatch({
      type: GET_WEBHOOK_EVENTS,
      payload: { events: [], enabled: false, healthy: false },
    });
  }
};

export const updateWebhookEvents = (
  orgId: string,
  userId: string,
  events: WebhookEventTypes[]
) => async (dispatch: Dispatch<UpdateWebhookEventsAction>): Promise<void> => {
  try {
    const eventsToSend = {
      enabledEvents: events,
    };

    const updatedEvents = await axios.post(
      `services/orgs/${orgId}/users/${userId}/webhooks`,
      eventsToSend
    );

    dispatch({
      type: UPDATE_WEBHOOK_EVENTS,
      payload: updatedEvents.data.payload,
    });
  } catch (e) {
    // handle ERROR
  }
};

export const sendSubscription = (
  orgId: string,
  userId: string,
  body: SubscriptionBodyToSend
) => async (): Promise<void> => {
  try {
    const bodyJson = JSON.stringify(body);

    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;

    await axios.post(
      `services/orgs/${orgId}/users/${userId}/support-contact-us`,
      bodyJson
    );
  } catch (e) {
    // handle ERROR
  }
};

export const toggleWebhookStatusModal = (
  flag: boolean
): ToggleWebhookModalAction => ({
  type: TOGGLE_WEBHOOK_STATUS_MODAL,
  payload: flag,
});

export const clearWebhookData = (): ClearWebhookDataAction => ({
  type: CLEAR_WEBHOOK_DATA,
});

export const resetWebhookStatus = (): ResetWebhookStatusAction => ({
  type: RESET_WEBHOOK_STATUS,
});
