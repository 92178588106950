import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { building, closeIcon, person, phone } from './assets';
import { RootState } from '../../store/store';
import { updateOrganization } from '../../store/org/org.actions';
import { updateUser } from '../../store/users/users.actions';
import { Input } from '../Input/Input';
// import CustomSelect from '../CustomSelect/CustomSelect';
import { toggleAddInfoModal } from '../../store/auth/auth.actions';

export const AddInfoModal: React.FC = (): JSX.Element => {
  const { user, org, isAddInfoModalActive } = useSelector(
    (state: RootState) => state.auth
  );

  const { search } = useLocation();

  const showPopupQuery = new URLSearchParams(search).get('show-warning-popup');

  const [companyName, setCompanyName] = useState('');

  const [phoneNumber, setPhoneNumber] = useState('');

  const [roleInput, setRoleInput] = useState('');

  const [curInfoIsSeted, setCurInfoisSeted] = useState(false);

  const [updateSuccess, setUpdateSuccess] = useState(false);

  const [closedWithoutSaving, setClosedWithoutSaving] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (updateSuccess) {
      dispatch(toggleAddInfoModal(false));
    }
  }, [updateSuccess, dispatch]);

  useEffect(() => {
    if (
      user &&
      org &&
      !isAddInfoModalActive &&
      !updateSuccess &&
      !closedWithoutSaving
    ) {
      if (
        (user.role === 'owner' && (!user.phoneNumber || !org.name)) ||
        ((user.role === 'admin' || user.role === 'dealmaker') &&
          (!user.phoneNumber || !user.title))
      ) {
        dispatch(toggleAddInfoModal(true));

        setCompanyName(org.name || '');
        setRoleInput(user.title || '');
        setPhoneNumber(user.phoneNumber || '');
      }
    }

    if (!curInfoIsSeted && user && org) {
      setCompanyName(org?.nameType === 'systemGenerated' ? '' : org.name);
      setPhoneNumber(user.phoneNumber || '');
      setCurInfoisSeted(true);
    } // eslint-disable-next-line
  }, [user, org, isAddInfoModalActive, curInfoIsSeted]);

  // const selectValues = [
  //   {
  //     value: 'Sales development representative',
  //     label: 'Sales development representative',
  //   },
  //   {
  //     value: 'Business development representative',
  //     label: 'Business development representative',
  //   },
  //   { value: 'Account executive', label: 'Account executive' },
  //   { value: 'Sales manager', label: 'Sales manager' },
  //   { value: 'Sales operations manager', label: 'Sales operations manager' },
  //   { value: 'Chief revenue officer', label: 'Chief revenue officer' },
  //   {
  //     value: 'Sales enablement specialist',
  //     label: 'Sales enablement specialist',
  //   },
  //   { value: 'Sales team lead', label: 'Sales team lead' },
  //   { value: 'Other', label: 'Other' },
  // ];

  // const [role, setRole] = useState('');

  const updateCompanyField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(event?.target?.value);
  };

  const updateRoleField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoleInput(event?.target?.value);
  };

  // const onRoleChange = (newValue: typeof selectValues[0]) => {
  //   setRole(newValue.value);
  // };

  const updateNumberField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regexNumbersOnly = /\D+/;
    const filter = event?.target?.value?.toUpperCase().trim();

    if (
      event?.target?.value?.replace(regexNumbersOnly, '') === filter ||
      event?.target?.value === filter
    ) {
      setPhoneNumber(event?.target?.value);
    }
  };

  const handleSubmit = () => {
    if (user) {
      if (
        user.role === 'owner' &&
        companyName?.length &&
        org?.name !== companyName
      ) {
        dispatch(updateOrganization({ name: companyName }, user.orgId, true));
        setUpdateSuccess(true);
      }

      if (phoneNumber?.length && user.phoneNumber !== phoneNumber) {
        const userInfoToUpdate = {
          title: roleInput,
          phoneNumber,
        };

        dispatch(updateUser(userInfoToUpdate, user.orgId, user.userId));
        setUpdateSuccess(true);
      }
    }
  };

  const closeWithoutSave = () => {
    setClosedWithoutSaving(true);
    dispatch(toggleAddInfoModal(false));
  };

  const buttonDisable =
    (user?.role === 'owner' && !companyName?.length) || !phoneNumber?.length;

  const attrs = {
    wrapper: {
      className: 'add-info',
      style:
        !isAddInfoModalActive || !showPopupQuery ? { display: 'none' } : {},
    },

    CssTransition: {
      unmountOnExit: true,
      in: isAddInfoModalActive && !showPopupQuery,
      classNames: 'add-info',
      timeout: 400,
    },

    overlay: {
      className: 'add-info-overlay',
      // onClick: onClose,
    },

    popup: {
      wrapper: {
        className: 'add-info__popup',
      },

      leftSection: {
        wrapper: {
          className: 'add-info__popup__left-section',
        },

        header: {
          className: 'add-info__popup__left-section-title',
        },

        formSection: {
          wrapper: {
            className: 'add-info__popup__left-section__form-section',
          },

          companyInput: {
            label: 'Company*',
            value: companyName,
            placeholder: 'Company name',
            onChange: updateCompanyField,
            name: 'company',
            icon: building,
            whiteVariant: true,
            className: 'add-info__popup__left-section__form-section-input',
          },

          phoneNumberInput: {
            label: 'Phone number*',
            value: phoneNumber,
            placeholder: '+1 1234-123-123',
            onChange: updateNumberField,
            name: 'phone',
            icon: phone,
            whiteVariant: true,
            className: 'add-info__popup__left-section__form-section-input',
          },

          roleInput: {
            label: 'Your role',
            value: roleInput,
            placeholder: 'What is your role',
            onChange: updateRoleField,
            name: 'role',
            icon: person,
            whiteVariant: true,
            className: 'add-info__popup__left-section__form-section-input',
          },

          // select: {
          //   label: 'Your role',
          //   placeholder: 'What is your role',
          //   items: selectValues,
          //   onChange: onRoleChange,
          //   style: {
          //     background: '#ffffff',
          //     height: '50px',
          //   },
          //   selectedItemJustify: 'flex-start',
          //   selectedItemFontSize: '14px',
          //   selectedItemFontFamily: 'Nexa Regular',
          //   selectedItemFontWeight: '400',
          //   icon: person,
          //   isInAddInfoPopup: true,
          //   className: 'add-info__popup__left-section__form-section-select',
          // },
        },

        footer: {
          wrapper: {
            className: 'add-info__popup__left-section__footer',
          },

          text: {
            className: 'add-info__popup__left-section__footer-text',
          },

          button: {
            className: `${
              buttonDisable ? 'disabled' : ''
            } add-info__popup__left-section__footer-button`,
            onClick: handleSubmit,
          },
        },
      },

      rightSection: {
        wrapper: {
          className: 'add-info__popup__right-section',
        },

        icon: {
          src: closeIcon,
          onClick: closeWithoutSave,
          className: 'add-info__popup__right-section-icon',
        },
      },
    },
  };

  const companyField =
    user?.role === 'owner' ? (
      <Input {...attrs.popup.leftSection.formSection.companyInput} />
    ) : null;

  return (
    <>
      <CSSTransition {...attrs.CssTransition}>
        <div {...attrs.overlay} />
      </CSSTransition>
      <CSSTransition {...attrs.CssTransition}>
        <div {...attrs.popup.wrapper}>
          <div {...attrs.popup.leftSection.wrapper}>
            <div {...attrs.popup.leftSection.header}>
              {user?.firstName}, please complete your details:
            </div>
            <div {...attrs.popup.leftSection.formSection.wrapper}>
              {companyField}
              {/* <CustomSelect {...attrs.popup.leftSection.formSection.select} /> */}
              <Input {...attrs.popup.leftSection.formSection.roleInput} />
              <Input
                {...attrs.popup.leftSection.formSection.phoneNumberInput}
              />
            </div>
            <div {...attrs.popup.leftSection.footer.wrapper}>
              <div {...attrs.popup.leftSection.footer.text}>
                *Required fields
              </div>
              <div {...attrs.popup.leftSection.footer.button}>Continue</div>
            </div>
          </div>
          <div {...attrs.popup.rightSection.wrapper}>
            <img {...attrs.popup.rightSection.icon} alt="" />
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
