import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import packageInfo from '../../../../../package.json';
import { userClickedSupport } from '../../../../store/auth/auth.actions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { LogoIcon } from './icons/LogoIcon';
import { RelationshipsIcon } from './icons/RelationshipsIcon';
import { MeetingsIcon } from './icons/MeetingsIcon';
import { PerformanceQIcon } from './icons/PerformanceQIcon';
import { TeamQIcon } from './icons/TeamQIcon';
import { AppsIcon } from './icons/AppsIcon';
import { SettingsIcon } from './icons/SettingsIcon';
import { SupportIcon } from './icons/SupportIcon';
import { User } from '../../../../types';
import { Tooltip, TooltipPosition } from '../Tooltip/Tooltip';
import { AccountIcon } from './icons/AccountIcon';

const getRoutes = (user: User | null) => {
  const { orgId, userId, role } = user || {};
  const isAdmin = role === 'admin' || role === 'owner' || role === 'superAdmin';

  return [
    {
      Icon: RelationshipsIcon,
      path: `/orgs/${orgId}/users/${userId}/relationships`,
      tooltip: 'Relationships',
    },
    {
      Icon: MeetingsIcon,
      path: `/orgs/${orgId}/users/${userId}/meeting-records`,
      tooltip: 'Meetings',
    },
    {
      Icon: PerformanceQIcon,
      path: `/orgs/${orgId}/users/${userId}/performanceq`,
      tooltip: 'Performance Q',
    },
    ...(isAdmin
      ? [
          {
            Icon: TeamQIcon,
            path: `/orgs/${orgId}/users/${userId}/team-analytics/overview`,
            tooltip: 'Team Q',
          },
        ]
      : []),
    ...(!isAdmin
      ? [
          {
            Icon: AppsIcon,
            path: `/orgs/${orgId}/users/${userId}/apps`,
            tooltip: 'Apps',
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            Icon: SettingsIcon,
            path: `/orgs/${orgId}/admin-settings`,
            tooltip: 'Settings',
          },
        ]
      : []),
    {
      Icon: AccountIcon,
      path: `/orgs/${orgId}/users/${userId}`,
      tooltip: 'Profile',
    },
  ];
};

export const Sidebar: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);

  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const { version } = packageInfo;

  const isPersonalQCompetence = pathname.includes('personalq_competence');

  const attrs = {
    container: {
      className: 'sound-wave-sidebar',
    },
    group: {
      className: 'sound-wave-sidebar__group',
    },
    groupGrow: {
      className: 'sound-wave-sidebar__group sound-wave-sidebar__group--grow',
    },
    nav: {
      className: 'sound-wave-sidebar__nav',
    },
    homeLink: {
      className: 'sound-wave-sidebar__nav-link',
      to: `/orgs/${user?.orgId}/users/${user?.userId}/relationships`,
    },
    icon: {
      className: 'sound-wave-sidebar__icon',
    },
    support: {
      className: 'sound-wave-sidebar__support',
    },
    supportButton: {
      type: 'button' as const,
      className: 'sound-wave-sidebar__support-button',
      onClick: () => {
        if (user) {
          dispatch(userClickedSupport(user.userId, user.orgId));
          window.open('https://help.substrata.me/en/', '_blank');
        }
      },
    },
    supportTooltip: {
      text: 'Support',
      dark: true,
      positionOffset: 38,
      position: TooltipPosition.RIGHT,
      contentClassName: 'sound-wave-sidebar__tooltip',
    },
  };

  const mappedRoutes = useMemo(
    () =>
      getRoutes(user).map((route, index) => {
        const { path, tooltip, Icon } = route;
        const isActive =
          path === pathname ||
          (path.includes('team-analytics') &&
            pathname.includes(path.replace('/overview', ''))) ||
          (path.includes('admin-settings') && pathname.includes(path));

        const props = {
          link: {
            key: index,
            className: 'sound-wave-sidebar__nav-link',
            to: path,
          },
          icon: {
            isActive,
            className: 'sound-wave-sidebar__icon',
          },
          tooltip: {
            text: tooltip,
            dark: true,
            positionOffset: 30,
            position: TooltipPosition.RIGHT,
            contentClassName: 'sound-wave-sidebar__tooltip',
          },
        };

        return (
          <Tooltip {...props.tooltip}>
            <Link {...props.link}>
              <Icon {...props.icon} />
            </Link>
          </Tooltip>
        );
      }),
    [pathname, user]
  );

  if (isPersonalQCompetence) {
    return null;
  }

  return (
    <aside {...attrs.container}>
      <div {...attrs.group}>
        <Link {...attrs.homeLink}>
          <LogoIcon {...attrs.icon} />
        </Link>
      </div>
      <div {...attrs.groupGrow}>
        <nav {...attrs.nav}>{mappedRoutes}</nav>
      </div>
      <div {...attrs.group}>
        <div {...attrs.support}>
          <span>Ver. {version}</span>
          <Tooltip {...attrs.supportTooltip}>
            <button {...attrs.supportButton}>
              <SupportIcon {...attrs.icon} />
            </button>
          </Tooltip>
        </div>
      </div>
    </aside>
  );
};
