import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  RouteProps,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { GlobalHeader, Scrollbar } from './components';
import { Sidebar } from './components/SoundWave/components/Sidebar/Sidebar';
import { Account, Apps, Maintenance, Teams, Users } from './pages';
import { Analytics } from './pages/Analytics/Analytics';
import { GoogleCallback } from './pages/GoogleCallback/GoogleCallback';
import { MyOrganization } from './pages/MyOrganization/MyOrganization';
import { refreshToken } from './store/auth/auth.actions';
import { RouteI, User } from './types';
import { SIGNUP_LINK } from './constants/config';
import { getCurrentPath } from './components/utils';
import { Stats } from './pages/Analytics/Stats';
import PersonalQForIframe from './components/PersonalQForIframe/PersonalQForIframe';
import { TeamQ } from './pages/TeamQ/TeamQ';
import { Submenu } from './components/Submenu/Submenu';
import { AccountSettings } from './pages/AccountSettings/AccountSettings';
import { TeamsForRedirect } from './pages/TeamsForRedirect/TeamsForRedirect';
import { DealsForRedirect } from './pages/MyDeals/PageToRedirect/DealsForRedirect';
import { OnboardingModal } from './components/OnboardingModal/OnboardingModal';
import { Billing } from './pages/Billing/Billing';
import { ExtensionNIWarnModal } from './components/ExtensionNIWarnModal/ExtensionNIWarnModal';
import { AddInfoModal } from './components/AddInfoModal/AddInfoModal';
import { NonPaidUserModal } from './components/NonPaidUserModal/NonPaidUserModal';
import { MyMeetings } from './pages/MyMeetings/MyMeetings';
import { MeetingDetails } from './pages/MeetingDetails/MeetingDetails';
import { ThreadDetails } from './pages/ThreadDetails/ThreadDetails';
import { PATHS } from './constants/paths';
import { MyRelationships } from './pages/MyRelationships/MyRelationships';
import { RelationshipDetails } from './pages/MyRelationships/components/relationshipDetails/RelationshipDetails';
import { AddAccountModal } from './components/AddAccountModal/AddAccountModal';
import { AddMemberModal } from './components/AddMemberModal/AddMemberModal';
import { MeetingRecords } from './pages/MeetingRecords/MeetingRecords';
import { AssignAccountModal } from './components/AssignAccountModal/AssignAccountModal';
import { BottomNotification } from './components/SoundWave/components/BottomNotification/BottomNotification';
import { AddNoteModal } from './components/AddNoteModal/AddNoteModal';
import { EngagementsRedirect } from './pages/MyDeals/EngagementsRedirect';
import { UnassignEventModal } from './components/UnassignEventModal/UnassignEventModal';
import { useAppDispatch, useAppSelector } from './store/hooks';

export const ROUTES: RouteI[] = [
  {
    path: PATHS.DEALS_REDIRECT,
    Component: DealsForRedirect,
    label: '',
    tooltip: '',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.RELATIONSHIPS,
    Component: MyRelationships,
    label: 'My Relationships',
    tooltip: 'My Relationships',
    roles: ['owner', 'admin', 'superAdmin', 'dealmaker'],
  },
  {
    path: PATHS.ENGAGEMENTS,
    Component: EngagementsRedirect,
    label: 'My Engagements ',
    tooltip: 'My Engagements',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.ENGAGEMENT_DETAILS,
    Component: EngagementsRedirect,
    label: 'My Engagements',
    tooltip: 'My Engagements',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.THREAD_DETAILS,
    Component: ThreadDetails,
    label: '',
    tooltip: '',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.TOUCH_POINT_DETAILS,
    Component: ThreadDetails,
    label: '',
    tooltip: '',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.EMAIL_STATS,
    Component: Stats,
    label: 'My Performance Q  ',
    tooltip: 'My Performance Q',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.PERFORMACE_Q,
    Component: Stats,
    label: 'My Performance Q  ',
    tooltip: 'My Performance Q',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.TEAM_ANALYTICS,
    Component: TeamQ,
    label: 'Overview',
    tooltip: 'Team Q',
    roles: ['owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.RELATIONSHIP_DETAILS,
    Component: RelationshipDetails,
    label: '',
    tooltip: '',
    roles: ['owner', 'admin', 'superAdmin', 'dealmaker'],
  },
  {
    path: PATHS.MEETINGS,
    Component: MyMeetings,
    label: 'My Meetings',
    tooltip: 'My Meetings',
    roles: ['owner', 'admin', 'superAdmin', 'dealmaker'],
  },
  {
    path: PATHS.MEETING_DETAILS,
    Component: MeetingDetails,
    label: 'My Meetings',
    tooltip: 'My Meetings',
    roles: ['owner', 'admin', 'superAdmin', 'dealmaker'],
  },
  {
    path: PATHS.MEETING_RECORDS,
    Component: MeetingRecords,
    label: 'My Meetings',
    tooltip: 'Meeting Records',
    roles: ['owner', 'admin', 'superAdmin', 'dealmaker'],
  },
  {
    path: PATHS.MEETING_RECORD,
    Component: MeetingDetails,
    label: 'My Meetings',
    tooltip: 'Meeting Records',
    roles: ['owner', 'admin', 'superAdmin', 'dealmaker'],
  },
  {
    path: PATHS.ADMIN_SETTINGS_APPS,
    Component: Apps,
    label: 'Apps',
    tooltip: 'Apps',
    roles: ['owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.ADMIN_SETTINGS_TEAMS,
    Component: Teams,
    label: 'Teams',
    tooltip: 'Teams',
    roles: ['owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.TEAMS_REDIRECT,
    Component: TeamsForRedirect,
    label: '',
    tooltip: '',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.ADMIN_SETTINGS_ORGANIZATION,
    Component: MyOrganization,
    label: 'My Organization',
    tooltip: 'My Organization',
    roles: ['owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.ORGANIZATION_DETAILS,
    Component: MyOrganization,
    label: 'My Organization',
    tooltip: 'My Organization',
    roles: ['owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.PERSONAL_Q,
    Component: Analytics,
    label: 'Performance Q',
    tooltip: 'Analytics',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.PERSONAL_Q_ANALYTICS,
    Component: Analytics,
    label: 'Performance Q ',
    tooltip: 'Analytics',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.PERSONAL_Q_COMPETENCE,
    Component: PersonalQForIframe,
    label: '',
    tooltip: '',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
    isIframe: true,
  },
  {
    path: PATHS.APPS,
    Component: Apps,
    label: 'Apps',
    tooltip: 'My Apps',
    roles: ['dealmaker'],
  },
  {
    path: PATHS.ACCOUNT,
    Component: Account,
    label: 'Account',
    tooltip: 'My Account',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.ADMIN_SETTINGS,
    Component: AccountSettings,
    label: 'Admin Settings',
    tooltip: 'Admin Settings',
    roles: ['owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.ADMIN_SETTINGS_USERS,
    Component: Users,
    label: 'Users',
    tooltip: 'Users',
    roles: ['owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.USERS,
    Component: Users,
    label: 'Users',
    tooltip: 'Users',
    roles: ['owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.ADMIN_SETTINGS_BILLING,
    Component: Billing,
    label: 'Billing',
    tooltip: 'Billing',
    roles: ['owner', 'admin', 'superAdmin'],
  },
];

interface ProtectedRouteProps extends RouteProps {
  roles: string[];
  user: User | null;
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  roles,
  user,
  children,
  ...props
}: ProtectedRouteProps): JSX.Element | null => {
  if (!localStorage.getItem('refresh')) return <Redirect to="/" />;

  return roles.includes(user?.role as string) && user?.isEmailVerified ? (
    <Route {...props}>
      {({ match }) => (
        <CSSTransition timeout={500} classNames="routes" in={match != null}>
          {children}
        </CSSTransition>
      )}
    </Route>
  ) : null;
};

export const AppRouting: React.FC = (): JSX.Element | null => {
  const { user, authError } = useAppSelector((state) => state.auth);

  const isMaintenanceMode = useAppSelector(
    (state) => state.global.isMaintenanceMode
  );

  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const relationshipsMatch = useRouteMatch(PATHS.RELATIONSHIPS);
  const meetingRecordsMatch = useRouteMatch(PATHS.MEETING_RECORDS);
  const teamQMatch = useRouteMatch(PATHS.TEAM_ANALYTICS);
  const performanceQMatch = useRouteMatch(PATHS.PERFORMACE_Q);
  const appsMatch = useRouteMatch(PATHS.APPS);
  const appsAdminMatch = useRouteMatch(PATHS.ADMIN_SETTINGS_APPS);
  const billingMatch = useRouteMatch(PATHS.ADMIN_SETTINGS_BILLING);

  const { boot } = useIntercom();

  const currentRoute = ROUTES.find(
    ({ path }) => getCurrentPath(path, user) === pathname
  );

  const attrs = {
    scrollable: {
      className: 'App__scrollable',
    },
  };

  useEffect(() => {
    if (!user && localStorage.getItem('token')) {
      if (authError.refresh) {
        window.location.href = `${SIGNUP_LINK}/logout`;
      }
      dispatch(refreshToken());
    }
  }, [authError, user, dispatch]);

  useEffect(() => {
    if (user && user.userHash) {
      const userInfo = {
        userId: `${user.orgId}|${user.userId}`,
        name: user.name,
        email: user.email,
        userHash: user.userHash,
        customAttributes: {
          userId: user.userId,
          orgId: user.orgId,
        },
      };

      boot(userInfo);
    } // eslint-disable-next-line
  }, [user?.userId]);

  const defineWrapperClassName = () => {
    if (
      relationshipsMatch?.isExact ||
      meetingRecordsMatch?.isExact ||
      teamQMatch ||
      performanceQMatch ||
      appsMatch ||
      appsAdminMatch ||
      billingMatch
    ) {
      return 'page page--new-design page--new-design-white';
    }

    if (currentRoute?.isIframe) {
      return '';
    }

    return 'page page--new-design';
  };

  const defineContentClassName = () => {
    if (currentRoute?.label === 'Stats') {
      return 'analytics-stats__page-content';
    }

    if (currentRoute?.isIframe) {
      return '';
    }

    return 'page__content';
  };

  const conditionalPageClassName = defineWrapperClassName();

  const conditionalContentClassName = defineContentClassName();

  if (isMaintenanceMode) {
    return <Maintenance />;
  }

  return (
    <div className={currentRoute?.isIframe ? '' : 'App'}>
      <Sidebar />
      <Submenu />
      <ExtensionNIWarnModal />
      <AddInfoModal />
      <OnboardingModal />
      <NonPaidUserModal />
      <AddAccountModal />
      <AddMemberModal />
      <AssignAccountModal />
      <UnassignEventModal />
      <AddNoteModal />
      <BottomNotification />
      <Scrollbar {...attrs.scrollable}>
        <div className={conditionalPageClassName}>
          {!currentRoute?.isIframe ? <GlobalHeader /> : null}
          <div className={conditionalContentClassName}>
            <TransitionGroup>
              <Switch>
                {ROUTES.map((route) => {
                  const { path, Component, roles } = route;
                  return (
                    <ProtectedRoute
                      roles={roles}
                      key={path}
                      exact
                      path={path}
                      user={user}
                    >
                      <Component />
                    </ProtectedRoute>
                  );
                })}
                <Route path="/google-callback">
                  <GoogleCallback />
                </Route>
                <Route path="*">
                  <Redirect to="/not-found" />
                </Route>
              </Switch>
            </TransitionGroup>
          </div>
        </div>
      </Scrollbar>
    </div>
  );
};
