import { Organization, User, UserActivity } from '../../types';
import { UPDATE_ORGANIZATION } from '../org/org.actions';
import { SubscriptionInfo } from '../org/org.types';
import { UPDATE_USER } from '../users/users.actions';
import {
  CALL_GOOGLE_CALLBACK,
  CALL_GOOGLE_CALLBACK_FAILURE,
  CLEAR_RESEND_EMAIL_MESSAGE,
  GOOGLE_SIGN_IN,
  REFRESH_TOKEN,
  RESEND_EMAIL,
  SEND_FORGOT_PASSWORD_EMAIL,
  SEND_FORGOT_PASSWORD_EMAIL_FAILURE,
  SIGN_IN,
  SIGN_OUT,
  TOGGLE_APP,
  VERIFY_RESET_PASSWORD_CODE,
  VERIFY_RESET_PASSWORD_CODE_FAILURE,
  REFRESH_TOKEN_ERROR,
  GET_APPS,
  SET_MIX_PANEL_IS_INITIALIZED,
  SET_USER_ACTIVITY,
  CRM_REQUEST_STATUS,
  RESET_CRM_STATUS,
  SET_ACCOUNT_TEAMS_TAB_ACTIVE,
  UPDATE_USER_DATA,
  SET_ONBOARDING_MODAL_ACTIVE,
  SET_ADD_INFO_MODAL_ACTIVE,
  SET_OFF_EXTENSION_N_I_POPUP,
  SIGN_IN_FAILURE,
  SET_SUBSCRIPTION_INFO,
} from './auth.actions';
import { AuthActions, CrmRequesStatus } from './auth.types';

export type AuthState = {
  user: User | null;
  userActivity: UserActivity | null;
  org: Organization | null;
  googleUrl: string;
  resendEmailSuccess: string;
  forgotPassword: {
    error: boolean;
    success: boolean;
  };
  authError: {
    signinFailure: boolean;
    common: boolean;
    google: boolean;
    refresh: boolean;
  };
  isSignedInUser: boolean;
  isMixPanelInitialized: boolean;
  crmRequestStatus: CrmRequesStatus;
  isAccountTeamTabActive: boolean;
  onboardingModalActive: boolean;
  isAddInfoModalActive: boolean;
  subscriptionInfo: SubscriptionInfo | null;
};

const initialState: AuthState = {
  user: null,
  userActivity: null,
  org: null,
  googleUrl: '',
  resendEmailSuccess: '',
  forgotPassword: {
    error: false,
    success: false,
  },
  authError: {
    signinFailure: false,
    common: false,
    google: false,
    refresh: false,
  },
  isSignedInUser: false,
  isMixPanelInitialized: false,
  crmRequestStatus: null,
  isAccountTeamTabActive: false,
  onboardingModalActive: false,
  isAddInfoModalActive: false,
  subscriptionInfo: null,
};

export const authReducer = (
  state: AuthState = initialState,
  action: AuthActions
): AuthState => {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        user:
          action.payload.userId === state.user?.userId
            ? action.payload
            : state.user,
      };

    case SET_USER_ACTIVITY:
      return {
        ...state,
        userActivity: action.payload,
      };

    case REFRESH_TOKEN:
    case CALL_GOOGLE_CALLBACK:
    case SIGN_IN:
      return {
        ...state,
        ...action.payload,
        authError: {
          signinFailure: false,
          common: false,
          google: false,
          refresh: false,
        },
        isSignedInUser: true,
      };

    case SIGN_IN_FAILURE:
      return {
        ...state,
        authError: {
          ...state.authError,
          signinFailure: true,
        },
      };

    case UPDATE_USER_DATA:
      return {
        ...state,
        user: action.payload,
      };

    case REFRESH_TOKEN_ERROR:
      return {
        ...state,
        authError: {
          ...state.authError,
          refresh: true,
        },
        isSignedInUser: true,
      };

    case UPDATE_ORGANIZATION:
      return {
        ...state,
        org: action.payload.isCurrentOrg ? action.payload.org : state.org,
      };

    case GOOGLE_SIGN_IN:
      return {
        ...state,
        googleUrl: action.payload,
        isSignedInUser: true,
      };

    case RESEND_EMAIL:
      return {
        ...state,
        resendEmailSuccess: action.payload,
      };

    case CLEAR_RESEND_EMAIL_MESSAGE:
      return {
        ...state,
        resendEmailSuccess: '',
      };

    case SIGN_OUT:
      return initialState;

    case VERIFY_RESET_PASSWORD_CODE_FAILURE:
    case SEND_FORGOT_PASSWORD_EMAIL_FAILURE:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          error: true,
          success: false,
        },
      };

    case VERIFY_RESET_PASSWORD_CODE:
    case SEND_FORGOT_PASSWORD_EMAIL:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          success: true,
          error: false,
        },
      };

    case GET_APPS:
      return {
        ...state,
        user: state.user && {
          ...state.user,
          apps: action.payload,
        },
      };

    case CRM_REQUEST_STATUS:
      return {
        ...state,
        crmRequestStatus: action.payload,
      };

    case RESET_CRM_STATUS:
      return {
        ...state,
        crmRequestStatus: null,
      };

    case TOGGLE_APP: {
      if (!state.user?.metadata?.features) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            features: state.user.metadata.features.map((feature) =>
              feature.name === action.payload.appName
                ? { ...feature, enabled: action.payload.active }
                : feature
            ),
          },
        },
      };
    }
    case CALL_GOOGLE_CALLBACK_FAILURE:
      return {
        ...state,
        authError: {
          ...state.authError,
          google: true,
        },
      };

    case SET_MIX_PANEL_IS_INITIALIZED:
      return {
        ...state,
        isMixPanelInitialized: true,
      };

    case SET_ACCOUNT_TEAMS_TAB_ACTIVE:
      return {
        ...state,
        isAccountTeamTabActive: action.payload,
      };

    case SET_ONBOARDING_MODAL_ACTIVE:
      return {
        ...state,
        onboardingModalActive: action.payload,
      };

    case SET_ADD_INFO_MODAL_ACTIVE:
      return {
        ...state,
        isAddInfoModalActive: action.payload,
      };

    case SET_OFF_EXTENSION_N_I_POPUP:
      return {
        ...state,
        user: state.user && {
          ...state.user,
          showInstallExtensionPopup: !action.payload,
        },
      };

    case SET_SUBSCRIPTION_INFO:
      return {
        ...state,
        subscriptionInfo: action.payload,
      };

    default:
      return state;
  }
};
