import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  ButtonSize,
  Dropdown,
  Tooltip,
  TooltipPosition,
} from '../../../../../../components/SoundWave';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  editModeChangesSelector,
  isActivateButtonDisabledSelector,
  isDeactivateButtonDisabledSelector,
  sideOptionDisabledFlagsSelector,
} from '../../../../../../store/relationshipDetails/relationshipDetails.selectors';
import {
  changeMembersActiveState,
  changeMembersAssignment,
  clearSelectedMembers,
} from '../../../../../../store/relationshipDetails/relationshipDetails.slice';
import {
  RelationshipMemberAssociationType,
  RelationshipMemberSide,
} from '../../../../../../types/relationshipMember.types';
import { EditModeChanges } from '../EditModeChanges/EditModeChanges';
import { ActivateIcon } from '../../icons/ActivateIcon';
import { AssignIcon } from '../../icons/AssignIcon';
import { CheckedIcon } from '../../icons/CheckedIcon';
import { CrossIcon } from '../../icons/CrossIcon';
import { DeactivateIcon } from '../../icons/DeactivateIcon';
import { showBottomNotification } from '../../../../../../store/bottomNotification/bottomNotification.slice';
import { useMixpanel } from '../../../../../../hooks';

export interface EditModeToolbarProps {
  openDiscardChangesModal: () => void;
  openApplyChangesModal: () => void;
}

export const EditModeToolbar: React.FC<EditModeToolbarProps> = ({
  openDiscardChangesModal,
  openApplyChangesModal,
}) => {
  const dispatch = useAppDispatch();

  const account = useAppSelector((state) => state.relationshipDetails.account);

  const userOrganization = useAppSelector((state) => state.auth.org);

  const selectedContactIds = useAppSelector(
    (state) => state.relationshipDetails.selectedContactIds
  );

  const isEditMode = useAppSelector(
    (state) => state.relationshipDetails.isEditMode
  );

  const isActivateButtonDisabled = useAppSelector(
    isActivateButtonDisabledSelector
  );

  const isDeactivateButtonDisabled = useAppSelector(
    isDeactivateButtonDisabledSelector
  );

  const editModeChanges = useAppSelector(editModeChangesSelector);

  const sideOptionDisabledFlags = useAppSelector(
    sideOptionDisabledFlagsSelector
  );

  const { trackEvent } = useMixpanel();

  const [isAssignDropdownOpened, setIsAssignDropdownOpened] = useState(false);

  const handleCloseAssignDropdown = useCallback(() => {
    setIsAssignDropdownOpened(false);
  }, []);

  const assignDropdownOptions = useMemo(
    () => [
      {
        className: 'edit-mode-toolbar__assign-option',
        label: account?.name
          ? `${account.name} Team Member`
          : 'Prospect Team Member',
        disabled: sideOptionDisabledFlags.prospectSideTeamMember,
        ...(sideOptionDisabledFlags.prospectSideTeamMember && {
          icon: <CheckedIcon />,
        }),
        onClick: () => {
          trackEvent('assignSelectedMembersToProspectSide');
          setIsAssignDropdownOpened(false);
          dispatch(
            showBottomNotification({
              text: 'Changes applied',
              position: 92,
            })
          );
          dispatch(
            changeMembersAssignment({
              contactIds: selectedContactIds,
              contactSide: RelationshipMemberSide.PROSPECT_SIDE,
              associationType: RelationshipMemberAssociationType.TEAM_MEMBER,
            })
          );
        },
      },
      {
        className: 'edit-mode-toolbar__assign-option',
        label: account?.name
          ? `${account.name} 3rd Party`
          : 'Prospect 3rd Party',
        disabled: sideOptionDisabledFlags.prospectSideThirdParty,
        ...(sideOptionDisabledFlags.prospectSideThirdParty && {
          icon: <CheckedIcon />,
        }),
        onClick: () => {
          trackEvent('assignSelectedMembersToProspectSide3rdParty');
          setIsAssignDropdownOpened(false);
          dispatch(
            showBottomNotification({
              text: 'Changes applied',
              position: 92,
            })
          );
          dispatch(
            changeMembersAssignment({
              contactIds: selectedContactIds,
              contactSide: RelationshipMemberSide.PROSPECT_SIDE,
              associationType: RelationshipMemberAssociationType.THIRD_PARTY,
            })
          );
        },
      },
      {
        className: 'edit-mode-toolbar__assign-option',
        label: userOrganization?.name
          ? `${userOrganization.name} Team Member`
          : 'User Team Member',
        disabled: sideOptionDisabledFlags.userSideTeamMember,
        ...(sideOptionDisabledFlags.userSideTeamMember && {
          icon: <CheckedIcon />,
        }),
        onClick: () => {
          trackEvent('assignSelectedMembersToUserSide');
          setIsAssignDropdownOpened(false);
          dispatch(
            showBottomNotification({
              text: 'Changes applied',
              position: 92,
            })
          );
          dispatch(
            changeMembersAssignment({
              contactIds: selectedContactIds,
              contactSide: RelationshipMemberSide.USER_SIDE,
              associationType: RelationshipMemberAssociationType.TEAM_MEMBER,
            })
          );
        },
      },
      {
        className: 'edit-mode-toolbar__assign-option',
        label: userOrganization?.name
          ? `${userOrganization.name} 3rd Party`
          : 'User 3rd Party',
        disabled: sideOptionDisabledFlags.userSideThirdParty,
        ...(sideOptionDisabledFlags.userSideThirdParty && {
          icon: <CheckedIcon />,
        }),
        onClick: () => {
          trackEvent('assignSelectedMembersToUserSide3rdParty');
          setIsAssignDropdownOpened(false);
          dispatch(
            showBottomNotification({
              text: 'Changes applied',
              position: 92,
            })
          );
          dispatch(
            changeMembersAssignment({
              contactIds: selectedContactIds,
              contactSide: RelationshipMemberSide.USER_SIDE,
              associationType: RelationshipMemberAssociationType.THIRD_PARTY,
            })
          );
        },
      },
      {
        className: 'edit-mode-toolbar__assign-option',
        label: 'Unassigned',
        disabled: sideOptionDisabledFlags.unassigned,
        ...(sideOptionDisabledFlags.unassigned && {
          icon: <CheckedIcon />,
        }),
        onClick: () => {
          trackEvent('unassignSelectedMembers');
          setIsAssignDropdownOpened(false);
          dispatch(
            showBottomNotification({
              text: 'Changes applied',
              position: 92,
            })
          );
          dispatch(
            changeMembersAssignment({
              contactIds: selectedContactIds,
              contactSide: RelationshipMemberSide.UNASSIGNED,
              associationType: RelationshipMemberAssociationType.TEAM_MEMBER,
            })
          );
        },
      },
    ],
    [
      account,
      dispatch,
      selectedContactIds,
      sideOptionDisabledFlags,
      trackEvent,
      userOrganization,
    ]
  );

  const attrs = {
    container: {
      className: `edit-mode-toolbar${
        isEditMode ? ' edit-mode-toolbar--visible' : ''
      }`,
    },
    unselectMembersButton: {
      type: 'button' as const,
      disabled: !selectedContactIds.length,
      className: 'edit-mode-toolbar__unselect-members-button',
      onClick: () => {
        trackEvent('clearSelectedMembers');
        dispatch(clearSelectedMembers());
      },
    },
    activateMembersButton: {
      size: ButtonSize.XS,
      disabled: isActivateButtonDisabled,
      className: 'edit-mode-toolbar__button',
      onClick: () => {
        trackEvent('activateSelectedMembers');

        dispatch(
          showBottomNotification({
            text: `${selectedContactIds.length} Members Marked as active`,
            position: 92,
          })
        );

        dispatch(
          changeMembersActiveState({
            contactIds: selectedContactIds,
            isActive: true,
          })
        );
      },
    },
    activateMembersIcon: {
      className: 'edit-mode-toolbar__activate-icon',
    },
    deactivateMembersButton: {
      size: ButtonSize.XS,
      disabled: isDeactivateButtonDisabled,
      className: 'edit-mode-toolbar__button',
      onClick: () => {
        trackEvent('deactivateSelectedMembers');

        dispatch(
          showBottomNotification({
            text: `${selectedContactIds.length} Members Marked as inactive`,
            position: 92,
          })
        );

        dispatch(
          changeMembersActiveState({
            contactIds: selectedContactIds,
            isActive: false,
          })
        );
      },
    },
    deactivateMembersIcon: {
      className: 'edit-mode-toolbar__deactivate-icon',
    },
    assignMembersButton: {
      size: ButtonSize.XS,
      disabled: !selectedContactIds.length,
      className: 'edit-mode-toolbar__button',
      onClick: () => {
        setIsAssignDropdownOpened(!isAssignDropdownOpened);
      },
    },
    assignMembersIcon: {
      className: 'edit-mode-toolbar__assign-icon',
    },
    assignDropdown: {
      isOpened: isAssignDropdownOpened,
      options: assignDropdownOptions,
      contentClassName: 'edit-mode-toolbar__assign-dropdown',
      onClose: handleCloseAssignDropdown,
    },
    discardChangesButton: {
      size: ButtonSize.XS,
      secondary: true,
      disabled: !editModeChanges.length,
      className: 'edit-mode-toolbar__discard-button',
      onClick: openDiscardChangesModal,
    },
    applyChangesTooltip: {
      width: 528,
      position: TooltipPosition.TOP_END,
      text: <EditModeChanges />,
      simplified: true,
      forcePositionUpdate: true,
      contentClassName: 'edit-mode-toolbar__apply-changes-tooltip',
    },
    applyChangesButton: {
      size: ButtonSize.XS,
      secondary: true,
      disabled: !editModeChanges.length,
      onClick: openApplyChangesModal,
    },
  };

  const renderApplyChanges = () => {
    if (!editModeChanges.length) {
      return (
        <Button {...attrs.applyChangesButton}>
          <span>Apply Changes</span>
        </Button>
      );
    }

    return (
      <Tooltip {...attrs.applyChangesTooltip}>
        <Button {...attrs.applyChangesButton}>
          <span>
            Apply Changes
            {editModeChanges.length ? ` (${editModeChanges.length})` : ''}
          </span>
        </Button>
      </Tooltip>
    );
  };

  return (
    <div {...attrs.container}>
      <button {...attrs.unselectMembersButton}>
        <span>
          {selectedContactIds.length
            ? `${selectedContactIds.length} Member${
                selectedContactIds.length === 1 ? '' : 's'
              } selected`
            : 'Select Members to edit'}
        </span>
        {selectedContactIds.length ? <CrossIcon /> : null}
      </button>
      <Button {...attrs.activateMembersButton}>
        <ActivateIcon {...attrs.activateMembersIcon} />
        <span>Activate</span>
      </Button>
      <Button {...attrs.deactivateMembersButton}>
        <DeactivateIcon {...attrs.deactivateMembersIcon} />
        <span>Deactivate</span>
      </Button>
      <Dropdown {...attrs.assignDropdown}>
        <Button {...attrs.assignMembersButton}>
          <AssignIcon {...attrs.assignMembersIcon} />
          <span>Assign</span>
        </Button>
      </Dropdown>
      <Button {...attrs.discardChangesButton}>
        <span>Discard</span>
      </Button>
      {renderApplyChanges()}
    </div>
  );
};
