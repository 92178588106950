import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { refreshToken, signIn } from '../../store/auth/auth.actions';
import { RootState } from '../../store/store';

import { SIGNUP_LINK } from '../../constants/config';
import { useAppSelector } from '../../store/hooks';
import { Maintenance } from '../Maintenance/Maintenance';

export const Login: React.FC = () => {
  const { user, org, authError } = useSelector(
    (state: RootState) => state.auth
  );

  const isMaintenanceMode = useAppSelector(
    (state) => state.global.isMaintenanceMode
  );

  const history = useHistory();

  const dispatch = useDispatch();

  const { search } = useLocation();

  const params = new URLSearchParams(search);

  const authCode = params.get('authCode');

  const isOpenedWithNIExtensionWarn =
    params.get('show-warning-popup') === 'true';

  const restrictRedirect = params.get('restrictRedirect') === 'true';

  const conditionRedirectToSignUp =
    (!restrictRedirect && !localStorage.getItem('token') && !authCode) ||
    authError.signinFailure;

  const conditionToRefreshToken =
    (!user || !org) && localStorage.getItem('token');

  const isFunctionalityDisabledCheck =
    typeof org?.isFunctionalityEnabled === 'boolean' &&
    !org.isFunctionalityEnabled;

  useEffect(() => {
    if (authCode) {
      dispatch(signIn(authCode));
    }
  }, [authCode, dispatch]);

  useEffect(() => {
    if (conditionRedirectToSignUp) {
      window.location.href = `${SIGNUP_LINK}/logout`;
    }

    if (conditionToRefreshToken) {
      if (authError.refresh) window.location.href = `${SIGNUP_LINK}/logout`;
      dispatch(refreshToken());
    }

    if (user && org) {
      if (isFunctionalityDisabledCheck) {
        history.push(`/orgs/${user.orgId}/admin-settings/billing`);
      } else if (isOpenedWithNIExtensionWarn) {
        history.push(
          `/orgs/${user.orgId}/users/${user.userId}/engagements?show-warning-popup=true`
        );
      } else {
        history.push(`/orgs/${user.orgId}/users/${user.userId}/engagements`);
      }
    }
  }, [
    user,
    org,
    isFunctionalityDisabledCheck,
    authError,
    conditionRedirectToSignUp,
    conditionToRefreshToken,
    dispatch,
    history,
    isOpenedWithNIExtensionWarn,
  ]);

  if (isMaintenanceMode) {
    return <Maintenance />;
  }

  return null;
};
