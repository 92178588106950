import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useClickOutside, useMixpanel } from '../../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { RelationshipFilter } from './components/RelationshipFilter/RelationshipFilter';
import {
  applyFilters,
  changeFilterValue,
  clearFilters,
  resetUnsavedFilters,
  toggleFilterActiveState,
  updateFiltersQueryParams,
} from '../../../../store/relationships/relationships.slice';
import {
  Filter,
  FilterValue,
} from '../../../../types/relationshipFilters.types';
import {
  appliedFiltersSelector,
  groupedFiltersSelector,
  unsavedFiltersSelector,
  updatedFiltersSelector,
} from '../../../../store/relationships/relationships.selectors';
import { FilterIcon } from './icons/FilterIcon';
import { FilterAppliedIcon } from './icons/FilterAppliedIcon';
import { getRelationshipsFiltersQueryParams } from '../../../../store/relationships/relationships.utils';
import { Button, ButtonSize } from '../../../../components/SoundWave';

export const RelationshipFilters: React.FC = () => {
  const dispatch = useAppDispatch();

  const groupedFilters = useAppSelector(groupedFiltersSelector);
  const updatedFilters = useAppSelector(updatedFiltersSelector);
  const unsavedFilters = useAppSelector(unsavedFiltersSelector);
  const appliedFilters = useAppSelector(appliedFiltersSelector);

  const { trackEvent } = useMixpanel();

  const [isOpened, setIsOpened] = useState(false);

  const [
    shouldUpdateFiltersQueryParams,
    setShouldUpdateFiltersQueryParams,
  ] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const counterValue = isOpened ? updatedFilters.length : appliedFilters.length;

  const attrs = {
    container: {
      className: 'relationship-filters',
      ref: containerRef,
    },
    toggleButton: {
      size: ButtonSize.S,
      secondary: true,
      onClick: () => {
        setIsOpened(!isOpened);
      },
    },
    appliedFiltersCounter: {
      className: 'relationship-filters__applied-filters-counter',
    },
    popup: {
      className: `relationship-filters__popup${
        isOpened ? ' relationship-filters__popup--opened' : ''
      }`,
    },
    popupHeader: {
      className: 'relationship-filters__popup-header',
    },
    popupContent: {
      className: 'relationship-filters__popup-content',
    },
    popupFooter: {
      className: 'relationship-filters__popup-footer',
    },
    clearButton: {
      size: ButtonSize.XS,
      disabled: !updatedFilters.length,
      secondary: true,
      onClick: () => {
        trackEvent('clearFilters');
        dispatch(clearFilters());
        setIsOpened(false);

        if (appliedFilters.length) {
          setShouldUpdateFiltersQueryParams(true);
        }
      },
    },
    applyButton: {
      size: ButtonSize.XS,
      disabled: !unsavedFilters.length,
      onClick: () => {
        trackEvent('applyFilters');
        dispatch(applyFilters());
        setShouldUpdateFiltersQueryParams(true);
        setIsOpened(false);
      },
    },
  };

  const renderFilter = useCallback(
    (filter: Filter<FilterValue>) => {
      const filterProps = {
        ...filter,
        key: filter.id,
        onActiveChange: (filterId: string) => {
          dispatch(toggleFilterActiveState(filterId));
        },
        onChange: (id: string, value: FilterValue) => {
          dispatch(changeFilterValue({ id, value }));
        },
      };

      return <RelationshipFilter {...filterProps} />;
    },
    [dispatch]
  );

  const renderFiltersGroup = useCallback(
    (filters: Filter<FilterValue>[], index: number) => {
      const props = {
        key: index,
        className: 'relationship-filters__filters-group',
      };

      return <div {...props}>{filters.map(renderFilter)}</div>;
    },
    [renderFilter]
  );

  useClickOutside(containerRef, () => {
    setIsOpened(false);
  });

  useEffect(() => {
    if (shouldUpdateFiltersQueryParams) {
      dispatch(
        updateFiltersQueryParams(
          getRelationshipsFiltersQueryParams(appliedFilters)
        )
      );
      setShouldUpdateFiltersQueryParams(false);
    }
  }, [appliedFilters, dispatch, shouldUpdateFiltersQueryParams]);

  useEffect(() => {
    if (!isOpened) {
      dispatch(resetUnsavedFilters());
    }
  }, [dispatch, isOpened]);

  return (
    <div {...attrs.container}>
      <Button {...attrs.toggleButton}>
        {appliedFilters.length ? <FilterAppliedIcon /> : <FilterIcon />}
        <span>Filters</span>
        {counterValue ? (
          <span {...attrs.appliedFiltersCounter}>{counterValue}</span>
        ) : null}
      </Button>
      <div {...attrs.popup}>
        <div {...attrs.popupHeader}>Select Filters</div>
        <div {...attrs.popupContent}>
          {groupedFilters.map(renderFiltersGroup)}
        </div>
        <div {...attrs.popupFooter}>
          <Button {...attrs.clearButton}>
            <span>Clear</span>
          </Button>
          <Button {...attrs.applyButton}>
            <span>Apply</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
