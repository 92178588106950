import React, { useMemo } from 'react';
import { PlusIcon } from '../../../Icons/PlusIcon';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { openAddBotModal } from '../../../../store/relationshipFlows/relationshipFlows.slice';
import { Tooltip, TooltipPosition, TooltipSize } from '../Tooltip/Tooltip';
import { Button, ButtonSize } from '../Button/Button';

export const InviteAssistantButton: React.FC = () => {
  const dispatch = useAppDispatch();

  const organization = useAppSelector((state) => state.auth.org);

  const isSoundWaveEnabled = useMemo(
    () =>
      organization?.enabledFeatures?.some(
        (feature) => feature === 'soundwave'
      ) || false,
    [organization]
  );

  const attrs = {
    button: {
      size: ButtonSize.S,
      disabled: !isSoundWaveEnabled,
      className: 'invite-assistant-button',
      onClick: () => {
        dispatch(openAddBotModal());
      },
    },
    tooltip: {
      simplified: true,
      size: TooltipSize.M,
      width: 256,
      text:
        'AI Assistant: Feature not available in current plan. Contact us to explore options.',
      position: TooltipPosition.BOTTOM_END,
      buttonProps: {
        label: 'Contact us',
        onClick: () => {
          window.open(
            'mailto:support@substrata.me',
            '_blank',
            'noopener noreferrer'
          );
        },
      },
    },
  };

  const renderButton = () => (
    <Button {...attrs.button}>
      <PlusIcon />
      <span>Invite Assistant</span>
    </Button>
  );

  if (!isSoundWaveEnabled) {
    return <Tooltip {...attrs.tooltip}>{renderButton()}</Tooltip>;
  }

  return renderButton();
};
