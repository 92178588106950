/* eslint-disable no-throw-literal */
import { AxiosError, AxiosRequestConfig } from 'axios';
import { Dispatch } from 'redux';
import axios from '../../axios';
import { StatusCode } from '../../types/statusCode.types';
import {
  ClearResendEmailMessageAction,
  GetAppsAction,
  GoogleSignInAction,
  ResendEmailAction,
  ResetCrmRequestStatus,
  SetAccountTeamsTabActive,
  SetAddInfoModalActive,
  SetMixPanelIsInitialized,
  SetOffExtensionNIPopup,
  SetOnboardingModalActive,
  SetSubscriptionInfoAction,
  SignInAction,
  SignInFailure,
  UpdateUserObj,
} from './auth.types';

export const SIGN_IN = 'SIGN_IN';

export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

export const SET_USER_ACTIVITY = 'SET_USER_ACTIVITY';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export const GOOGLE_SIGN_IN = 'GOOGLE_SIN_IN';

export const CALL_GOOGLE_CALLBACK = 'CALL_GOOGLE_CALLBACK';
export const CALL_GOOGLE_CALLBACK_FAILURE = 'CALL_GOOGLE_CALLBACK_FAILURE';

export const RESEND_EMAIL = 'RESEND_EMAIL';
export const CLEAR_RESEND_EMAIL_MESSAGE = 'CLEAR_RESEND_EMAIL_MESSAGE';

export const SIGN_OUT = 'SIGN_OUT';

export const SEND_FORGOT_PASSWORD_EMAIL = 'SEND_FORGOT_PASSWORD_EMAIL';
export const SEND_FORGOT_PASSWORD_EMAIL_FAILURE =
  'SEND_FORGOT_PASSWORD_EMAIL_FAILURE';
export const VERIFY_RESET_PASSWORD_CODE = 'VERIFY_RESET_PASSWORD_CODE';
export const VERIFY_RESET_PASSWORD_CODE_FAILURE =
  'VERIFY_RESET_PASSWORD_CODE_FAILURE';

export const GET_APPS = 'GET_APPS';
export const TOGGLE_APP = 'TOGGLE_APP';

export const CRM_REQUEST_STATUS = 'CRM_REQUEST_SUCCESS';
export const RESET_CRM_STATUS = 'RESET_CRM_STATUS';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR';

export const SET_ONBOARDING_MODAL_ACTIVE = 'SET_ONBOARDING_MODAL_ACTIVE';

export const SET_ADD_INFO_MODAL_ACTIVE = 'SET_ADD_INFO_MODAL_ACTIVE';

export const SET_MIX_PANEL_IS_INITIALIZED = 'SET_MIX_PANEL_IS_INITIALIZED';

export const SET_ACCOUNT_TEAMS_TAB_ACTIVE = 'SET_ACCOUNT_TEAMS_TAB_ACTIVEF';

export const SET_OFF_EXTENSION_N_I_POPUP = 'SET_OFF_EXTENSION_N_I_POPUP';

export const SET_SUBSCRIPTION_INFO = 'SET_SUBSCRIPTION_INFO';

export const signIn = (authCode: string) => async (
  dispatch: Dispatch<SignInAction | SignInFailure>
): Promise<void> => {
  try {
    const user = await axios.post('/auth-code', { authCode }, {
      retry: true,
    } as AxiosRequestConfig);
    localStorage.setItem('token', user.data.payload.jwt);
    localStorage.setItem('refresh', user.data.payload.refreshJWTToken);
    localStorage.setItem('tokenExpiresAt', user.data.payload.jwtExpiresAt);
    const org = await axios.get(`/services/orgs/${user.data.payload.orgId}`);
    dispatch({
      type: SIGN_IN,
      payload: { user: user.data.payload, org: org.data.payload },
    });
  } catch (e) {
    if ((e as AxiosError)?.response?.status === StatusCode.ServiceUnavailable) {
      return;
    }

    dispatch({
      type: SIGN_IN_FAILURE,
      payload: true,
    });
  }
};

export const googleSignIn = () => async (
  dispatch: Dispatch<GoogleSignInAction>
): Promise<void> => {
  const response = await axios.get(`/google-login/dashboard`);
  dispatch({
    type: GOOGLE_SIGN_IN,
    payload: `${response.data.payload.url}`,
  });
};

export const callGoogleCallback = (search: string) => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    const res = await axios.get(`/google-callback/dashboard${search}`, {
      retry: true,
    } as AxiosRequestConfig);
    const user = res.data.payload;
    localStorage.setItem('refresh', user.refreshJWTToken);
    localStorage.setItem('token', user.jwt);
    localStorage.setItem('tokenExpiresAt', user.jwtExpiresAt);
    const org = await axios.get(`/services/orgs/${user.orgId}/`);
    dispatch({
      type: CALL_GOOGLE_CALLBACK,
      payload: { user, org: org.data.payload },
    });
  } catch (e) {
    dispatch({
      type: CALL_GOOGLE_CALLBACK_FAILURE,
    });
  }
};

export const resendEmail = () => async (
  dispatch: Dispatch<ResendEmailAction>
): Promise<void> => {
  const res = await axios.get('/request-verify-email');
  if (res.data.code === 200)
    dispatch({
      type: RESEND_EMAIL,
      payload: 'Email was resent successfully',
    });
};

export const clearResendEmailMessage = (): ClearResendEmailMessageAction => ({
  type: CLEAR_RESEND_EMAIL_MESSAGE,
});

export const signOut = () => async (): Promise<void> => {
  try {
    await axios.get('/logout');
  } catch (e) {
    // Handle err
  }
};

export const getApps = (orgId: string, userId: string) => async (
  dispatch: Dispatch<GetAppsAction>
): Promise<void> => {
  const appsResponse = await axios.get(
    `services/orgs/${orgId}/users/${userId}/apps`
  );
  dispatch({
    type: GET_APPS,
    payload: appsResponse.data.payload,
  });
};

export const toggleApp = (
  active: boolean,
  appName: string,
  orgId: string
) => async (dispatch: Dispatch): Promise<void> => {
  try {
    await axios.patch(`/services/orgs/${orgId}/apps/${appName}`, {
      active,
    });

    dispatch({
      type: TOGGLE_APP,
      payload: { active, appName },
    });
  } catch (e) {
    // catch err
  }
};

export const SendContactCrmRequest = (
  userId: string,
  orgId: string,
  crmName: string
) => async (dispatch: Dispatch): Promise<void> => {
  try {
    await axios.post(`services/orgs/${orgId}/users/${userId}/contact-request`, {
      crmName,
    });

    dispatch({
      type: CRM_REQUEST_STATUS,
      payload: 'success',
    });
  } catch (e) {
    dispatch({
      type: CRM_REQUEST_STATUS,
      payload: 'error',
    });
  }
};

export const resetCrmRequestStatus = (): ResetCrmRequestStatus => ({
  type: RESET_CRM_STATUS,
});

export const refreshToken = () => async (dispatch: Dispatch): Promise<void> => {
  try {
    const refresh = localStorage.getItem('refresh');
    if (!refresh) return;
    const res = await axios.post('/refresh-token', { refreshToken: refresh }, {
      retry: true,
    } as AxiosRequestConfig);
    const user = res.data.payload;
    localStorage.setItem('refresh', user.refreshJWTToken);
    localStorage.setItem('token', user.jwt);
    localStorage.setItem('tokenExpiresAt', user.jwtExpiresAt);
    const org = await axios.get(`/services/orgs/${user.orgId}`);
    dispatch({
      type: REFRESH_TOKEN,
      payload: {
        user,
        org: org.data.payload,
      },
    });
  } catch (e) {
    if ((e as AxiosError)?.response?.status === StatusCode.ServiceUnavailable) {
      return;
    }

    localStorage.removeItem('refresh');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiresAt');

    dispatch({
      type: REFRESH_TOKEN_ERROR,
    });
  }
};

export const getUserData = (orgId: string, userId: string) => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    const userDataResponse = await axios.get(
      `/services/orgs/${orgId}/users/${userId}?analyzedEmails=true&features=true`
    );

    const userActivityResponse = await axios.get(
      `services/orgs/${orgId}/users/${userId}/activity`
    );

    dispatch({
      type: SIGN_IN,
      payload: {
        user: userDataResponse.data.payload,
      },
    });

    dispatch({
      type: SET_USER_ACTIVITY,
      payload: userActivityResponse.data.payload,
    });
  } catch (e) {
    // Handle error
  }
};

export const userClickedSupport = (
  userId: string,
  orgId: string
) => async (): Promise<void> => {
  try {
    axios.post(`services/orgs/${orgId}/users/${userId}/support`);
  } catch (e) {
    // Handle error
  }
};

export const setMixpanelInitializedSuccess = (): SetMixPanelIsInitialized => ({
  type: SET_MIX_PANEL_IS_INITIALIZED,
});

export const setAccountTeamsSectionActive = (
  flag: boolean
): SetAccountTeamsTabActive => ({
  type: SET_ACCOUNT_TEAMS_TAB_ACTIVE,
  payload: flag,
});

export const setWelcomeModalIsViewed = (
  userId: string,
  orgId: string,
  flag: boolean
) => async (
  dispatch: Dispatch<UpdateUserObj | SetOnboardingModalActive>
): Promise<void> => {
  try {
    dispatch({
      type: SET_ONBOARDING_MODAL_ACTIVE,
      payload: false,
    });

    const updatedUser = await axios.patch(
      `services/orgs/${orgId}/users/${userId}/welcome?showPopup=${flag}`
    );

    dispatch({
      type: UPDATE_USER_DATA,
      payload: updatedUser.data.payload,
    });
  } catch (e) {
    // Handle error
  }
};

export const toggleOnboardingModal = (
  flag: boolean
): SetOnboardingModalActive => ({
  type: SET_ONBOARDING_MODAL_ACTIVE,
  payload: flag,
});

export const toggleAddInfoModal = (flag: boolean): SetAddInfoModalActive => ({
  type: SET_ADD_INFO_MODAL_ACTIVE,
  payload: flag,
});

export const sendDontShowExtensionNIPopup = (
  orgId: string,
  userId: string
) => async (dispatch: Dispatch<SetOffExtensionNIPopup>): Promise<void> => {
  try {
    const body = {
      flags: [
        {
          name: 'showInstallExtensionPopup',
          value: false,
        },
      ],
    };

    axios.patch(`services/orgs/${orgId}/users/${userId}/flags`, body);

    dispatch({ type: SET_OFF_EXTENSION_N_I_POPUP, payload: true });
  } catch (e) {
    // Handle error
  }
};

export const setDontShowExtensionNIPopup = (
  flag: boolean
): SetOffExtensionNIPopup => ({
  type: SET_OFF_EXTENSION_N_I_POPUP,
  payload: flag,
});

export const getSubscriptionStatus = (orgId: string) => async (
  dispatch: Dispatch<SetSubscriptionInfoAction>
): Promise<void> => {
  try {
    const response = await axios.get(
      `/services/billing/orgs/${orgId}/subscription`
    );

    dispatch({
      type: SET_SUBSCRIPTION_INFO,
      payload: response.data.payload.payload,
    });
  } catch (e) {
    // handle ERROR
    // console.log(e);
  }
};
